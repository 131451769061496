import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import { Inject, Injectable } from '@angular/core';
import {
  PlatformCountry,
  WINDOW,
  KnownUserType,
  KnownUserTypes,
  AdobeMarketplace,
  AdobeAffiliate,
  AdobeCompany,
  AdobeUserAnalyticsData,
  AdobeAnalyticsData,
  ADOBE_ANALYTICS_LOGIN_STATUS,
  ADOBE_ANALYTICS_LOGIN_TYPES,
} from 'g3-common-ui';
import { ConfigApiResponse, ConfigService } from '../config.service';
import { ProfileService } from '../profile/profile.service';
import { ProfileResponse } from '../profile/profile.interfaces';
import { KnownUserService } from '../known-user.service';
import dayjs from 'dayjs';

@Injectable()
export class AdobeDataCollectorService {

  constructor(
    private profileService: ProfileService,
    private configService: ConfigService,
    private knownUserService: KnownUserService,
    @Inject(WINDOW) private window: WINDOW
  ) { }

  public getEnvironment(): string {
    const hostName = document.location.hostname;
    if (hostName.includes('g3staging')) {
      return 'staging';
    } else if (hostName.includes('g3dev') || hostName.includes('ssldev') || hostName.includes('local')) {
      return 'dev';
    } else if (hostName.includes('g3rc') || hostName.includes('g3rc2')) {
      return 'rc';
    } else {
      return 'production';
    }
  }

  public getAffiliateData(): AdobeAffiliate {
    const marketplace = this.configService.getConfig();

    return {
      id: marketplace.salesforce_corporate_data.affiliate_id,
      name: marketplace.salesforce_corporate_data.affiliate_name
    };
  }

  private getMarketplaceData(profileData: ProfileResponse): AdobeMarketplace {
    const configData: ConfigApiResponse = this.configService.getConfig();
    const countryData = !isEmpty(configData.allowed_countries)
      ? configData.allowed_countries.find(c => c.country_code === profileData.country_last)
      : null;

    return {
      id: configData.guid,
      name: configData.name,
      subdomain: configData.subdomain,
      country: this.getMarketplaceCountry(countryData, configData)
    };
  }

  private getCompanyData(): AdobeCompany {
    const marketplace = this.configService.getConfig();

    return {
      id: marketplace.wag3_admin_guid || '',
      name: marketplace.salesforce_account_name || '',
      industry: marketplace.salesforce_corporate_data.industry || ''
    };
  }

  private getProfileData(profileData: ProfileResponse): Partial<AdobeUserAnalyticsData> {
    const marketplaceData = this.configService.getConfig();
    const isKnownUser = this.knownUserService.isKnownUser();
    const knownUserType = this.knownUserService.knowUserType;
    const kmowmUserEmail = this.knownUserService.knowUserEmail;
    const encryptedEmail = get(profileData, 'encrypted_email', '');
    const user: Partial<AdobeUserAnalyticsData> = {
      guid: this.getUserGuid(profileData.guid),
      loginstatus: this.getLoginStatus(profileData.email, kmowmUserEmail),
      devicetype: this.getDeviceType(),
      enrolldate: this.getFirstAccessDate(profileData)
    };

    if (encryptedEmail) {
      user.email = encryptedEmail;
    }

    const state = get(profileData, 'location.nearest.state', '');
    if (state) {
      user.state = state;
    }

    const zipcode = get(profileData, 'zip_code', '');
    if (zipcode) {
      user.zipcode = zipcode;
    }

    user.logintype = this.setLoginType(marketplaceData, profileData, isKnownUser, knownUserType);

    return user;
  }

  public getPageUrl(): string {
    return this.window.location.href;
  }

  public async setPageData(data: { name: string; section: string; type: string; hierarchy: string[]; techstack?: string }): Promise<void> {
    const profileData = await this.profileService.getProfile().toPromise();

    this.window.ebg.analytics = {
      page: {
        techstack: 'g3',
        environment: this.getEnvironment(),
        company: this.getCompanyData(),
        marketplace: this.getMarketplaceData(profileData),
        affiliate: this.getAffiliateData(),
        url: this.getPageUrl(),
        ...data
      },
      user: this.getProfileData(profileData)
    };
  }

  public setHierarchy(page: string): Array<string> {
    const wag3 = window.location.hostname.includes('workingadvantage.com') && 'WAG3';
    const bp = window.location.hostname.includes('beneplace.com') && 'BP';

    const hierarhcy = [page];

    if (wag3 || bp) {
      hierarhcy.unshift(wag3 || bp);
    }

    return hierarhcy;
  }

  private getDeviceType(): string {
    return this.window.innerWidth < 1280 ? 'mobile' : 'desktop';
  }

  private getFirstAccessDate(profileData: ProfileResponse, format = 'MM/DD/YYYY'): string {
    return profileData.first_access_date ? dayjs(profileData.first_access_date).format(format) : '';
  }

  private getMarketplaceCountry(countryData: PlatformCountry, marketplaceData: ConfigApiResponse): string {
    if (!isEmpty(countryData)) {
      return countryData.country_code;
    } else {
      return marketplaceData.allowed_countries.length > 0 ? marketplaceData.allowed_countries[0].country_code : '';
    }
  }

  private setLoginType(
    marketplaceData: ConfigApiResponse,
    profileData: ProfileResponse,
    isKnownUser = false,
    knownUserType?: KnownUserType
  ): string {
    if (isKnownUser && knownUserType) {
      switch (knownUserType) {
        case KnownUserTypes.NOT_SET_UNCONFIRMED:
          return ADOBE_ANALYTICS_LOGIN_TYPES.UNCONFIRMED;
        case KnownUserTypes.SET_UNCONFIRMED:
          return ADOBE_ANALYTICS_LOGIN_TYPES.UNCONFIRMED;
        case KnownUserTypes.NOT_SET_CONFIRMED:
          return ADOBE_ANALYTICS_LOGIN_TYPES.UNAUTHENTICATED;
        case KnownUserTypes.SET_CONFIRMED:
          return ADOBE_ANALYTICS_LOGIN_TYPES.UNAUTHENTICATED;
        case KnownUserTypes.CHILD:
          return ADOBE_ANALYTICS_LOGIN_TYPES.CHILD;
      }
    }
    if (marketplaceData.auth_path || marketplaceData.is_sso || profileData.external_authentication === 'google') {
      return `sso_${marketplaceData.sign_in_mode.includes('sso') ? marketplaceData.sign_in_mode.replace('sso_', '') : profileData.external_authentication}`;
    } else if (!profileData.email) {
      return ADOBE_ANALYTICS_LOGIN_TYPES.GUEST;
    } else {
      return ADOBE_ANALYTICS_LOGIN_TYPES.STANDARD;
    }
  }

  private getLoginStatus(email: string, knownUserEmail?: string): string {
    return (email || knownUserEmail) ? ADOBE_ANALYTICS_LOGIN_STATUS.LOGGED_IN : ADOBE_ANALYTICS_LOGIN_STATUS.ANONYMOUS;
  }

  private getUserGuid(profileGuid: string): string {
    if (this.knownUserService.knowUserType === 'known_child' || this.knownUserService.knowUserChildDlk) {
      return this.knownUserService.knowUserChildDlk;
    }
    if (this.knownUserService.knowUserType || this.knownUserService.knowUserGuid) {
      return this.knownUserService.knowUserGuid;
    }
    return profileGuid;
  }

}
