import { KeyValue } from '@angular/common';
import { OfferExternalData } from '@shared/models/offer.model';

export enum AccessSettingOption {
  AvailableToAll = 'available_to_all',
  PromptForAuth = 'prompt_for_auth',
  HiddenToGuestKnown = 'hidden_to_guest_known',
  HiddenToGuest = 'hidden_to_guest'
}

export interface SearchOfferItem {
  id: string;
  guid: string;
  header_short: string;
  header_long: string;
  promo: string;
  description_search: string;
  description: string;
  code: string;
  images: {
    url: string;
  }[];
  is_allowed_cta: boolean;
  is_allowed_url: boolean;
  has_destination_url: boolean;
  destination_url: string;
  destination_url_new_tab: boolean;
  destination_url_type: string;
  vendor: {
    guid: string;
    title: string;
    logo_square: string;
    logo_wide: string;
  };
  vendor_brand: {
    title: string;
    logo_square: string;
  };
  categories: {
    guid: string;
    title: string;
  }[];
  is_saved: number;
  vendor_description: string;
  description_highlights: string;
  description_disclaimer: string;
  description_details: string;
  updated_date: string;
  status: string;
  type: string;
  questions_answers: string;
  cta_button_title: string;
  extra_status: string;
  views: number;
  end_date: string;
  start_date: string;
  class: string;
  keywords: string[];
  stop_keywords: string[];
  keywords_string: string;
  is_coupon_hidden: boolean;
  phone?: string;
  phone_note?: string;
  phone_action?: string;
  instruction_text?: string;
  code_instructions?: string;
  priority?: number;
  cat_code_tag_ids?: number[];
  product_sub_heading?: string;
  brand_override?: string;
  custom_product_sub_headings?: string;
  append_url_params?: string;
  access_setting?: AccessSettingOption;
  source_based_params?: KeyValue<string, string>[];
  external_source?: string;
  external_data: OfferExternalData;
  paylogixSubscription: KeyValue<string, string>;
  paylogix_enrolled_header_short?: string;
  append_internal_url_params?: string;
  append_g3_url_params?: string;
  is_widget_iframe?: boolean;
  is_widget_disclaimer: boolean;
  widget_code?: string;
  widget_disclaimer?: string;
  brand_tag_display?: string;
  tdd_tag_text?: string;
  tdd_orig_price?: string;
  tdd_curr_price?: string;
  tdd_offer_group?: boolean;
  tdd_pinned?: boolean;
  is_ebg?: boolean;
  shopping_category?: string;
}
