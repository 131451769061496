/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { ActivatedRoute } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import get from 'lodash-es/get';
import { AutocompleteCallback } from '@app/widgets/interfaces/autocomplete-callback.interface';
import { AutocompleteSettings } from '@widgets/interfaces/autocomplete-settings.interface';
import { defaultRoom } from '@app/widgets/common/rooms-and-guests/room.constants';
import { HotelRoomsService } from '@app/widgets/services/hotel-rooms.service';
import { HotelsState } from '@app/widgets/interfaces/widgets-state.interface';
import { ModalAlertComponent } from '@shared/components/modal-alert/modal-alert.component';
import { NgbDateCustomParserFormatter } from '@app/widgets/services/datepicker-parser.service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoomData, RoomsEvent } from '@app/widgets/common/rooms-and-guests/room-data.interface';
import { SearchWidgetsData } from '@offers/interfaces/search-widgets.interface';
import { SessionStorageService } from '@app/widgets/services/session-storage.service';
import { WidgetSearchAbstract } from '../widget-search.abstract';
import { WidgetsService } from '@widgets/services/widgets.service';
import { WidgetSuggestionService } from '@widgets/services/widget-suggestion.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { OfferBadgeSettingsModel } from '@app/shared/models/offer-badge-settings.model';
import { CustomWidgetModel } from '@app/shared/models/custom-widget.model';
import { ConfigService } from '@app/shared/services/config.service';
import { OfferBadgeService } from '@app/shared/services/offer-badge.service';
import { OfferBadgePropertiesModel } from '@app/shared/models/offer-badge-properties.model';
import { OfferBadgeData } from '@app/shared/interfaces/offer-badge-data.interface';
import { ValidCustomWidgetIdTypes } from '@app/widgets/constants/widget-ids.constant';

const DEFAULT_ROOM = 1;
const DEFAULT_ROOM_OCCUPANTS = 2;
const INITIAL_DAY_TO_BOOK = 2;
const STANDARD_DAY_TO_BOOK = 3;

@Component({
  selector: 'app-hotels-search-widget',
  templateUrl: './hotels-search.component.html',
  styleUrls: ['./hotels-search.component.less', '../common-widget.style.less']
})
export class HotelsSearchWidgetComponent extends WidgetSearchAbstract implements OnInit, AfterViewInit, OnDestroy {

  @Input() customClass = '';
  @Input() widget: SearchWidgetsData;
  @Input() topIndent: boolean;

  @Output() clicked = new EventEmitter<SearchWidgetsData>();
  @Output() displayed = new EventEmitter<SearchWidgetsData>();

  autocompleteSettings: AutocompleteSettings = {
    inputPlaceholderText: '',
    showSearchButton: false,
    currentLocIconUrl: '/assets/icons/current-location.png',
    locationIconUrl: '/assets/icons/location.png',
    showRecentSearch: false,
    inputString: ''
  };
  autocompleteOptions: { inputString: string } = { inputString: ''};
  minValueCheckOut: string;
  minValueCheckIn: string;
  rooms = DEFAULT_ROOM;
  room_occupants = DEFAULT_ROOM_OCCUPANTS;
  placeName = '';
  placeLat = '';
  placeLng = '';
  stateKey = '';
  widgetState: HotelsState;
  showGuestsAndRoomsData = false;
  roomsAndGuestsTitle = '';
  roomsData: RoomData[] = [defaultRoom];
  roomFormData = {};
  minDate: NgbDateStruct;
  checkIn: NgbDateStruct;
  checkOut: NgbDateStruct;
  checkInDate: string;
  checkOutDate: string;
  badgeModel: OfferBadgeData;

  private readonly currentDate = new Date();
  protected doubleDotForField = false;

  constructor(
    public datepickerParser: NgbDateCustomParserFormatter,
    private modalService: NgbModal,
    private widgetsService: WidgetsService,
    private cdr: ChangeDetectorRef,
    private hotelRoomsService: HotelRoomsService,
    private sessionStorageService: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    private widgetSuggestionService: WidgetSuggestionService
  ) {
    super();
    this.minDate = datepickerParser.toNgbDate(new Date());
    this.checkIn = datepickerParser.toNgbDate(new Date());
    this.checkOut = datepickerParser.toNgbDate(new Date(), 2);
  }

  @HostListener('window:resize', ['$event'])
  onResize(e: Event): void {
    super.setImageOptions((e.target as Window).innerWidth);
  }

  ngOnInit(): void {
    this.roomsAndGuestsTitle = this.hotelRoomsService.getRoomsAndGuestsTitle();
    this.minValueCheckOut = this.widgetsService.formatDate(this.currentDate);
    this.minValueCheckIn = this.widgetsService.formatDate(this.currentDate);
    this.stateKey = `${this.widget.widget_id}_${this.getOrderedParams()}`;
    this.widgetState = this.sessionStorageService.getItem<HotelsState>(this.stateKey);
    if (this.widgetState) {
      this.getWidgetState();
    }

    this.badgeModel = {
      catCodeTagIds: this.widgetsService.filterBadgesCatCodes(this.widget.widget_id, this.widget.cat_code_tag_ids)
    };

    super.ngOnInit();
  }

  public autoCompleteCallback(event: AutocompleteCallback): void {
    const { data } = event;

    if (data && data.formatted_address) {
      this.placeName = data.formatted_address;
    }

    if (data && data.geometry && data.geometry.location.lat) {
      this.placeLat = data.geometry.location.lat;
    }

    if (data && data.geometry && data.geometry.location.lng) {
      this.placeLng = data.geometry.location.lng;
    }
  }

  public handleAddressChange(value: Address): void {
    if (!value) {
      return;
    }

    const geoLocation = get(value, 'geometry.location');
    type AddressComponent = Address['address_components'][0];

    this.placeName = get(value, 'address_components')
      .filter((el: AddressComponent) => !el.types.includes('administrative_area_level_2'))
      .map((el: AddressComponent) => el.short_name)
      .join(', ');

    this.placeLat = geoLocation.lat().toString();
    this.placeLng = geoLocation.lng().toString();
  }

  public search(): boolean {
    this.checkInDate = this.datepickerParser.convertDateToFormat(this.datepickerParser.format(this.checkIn));
    this.checkOutDate = this.datepickerParser.convertDateToFormat(this.datepickerParser.format(this.checkOut));
    if (isEmpty(this.roomFormData)) {
      this.roomFormData = this.hotelRoomsService.getDefaultRoomsWrapperData();
    }
    if (!this.placeName || !this.placeLat && !this.placeLng) {
      this.alertMessage('Please enter a travel destination.');
    } else if (!this.checkIn || !this.checkOut) {
      this.alertMessage('Please enter both a check-in and check-out date.');
    } else {
      this.clicked.emit(this.widget);
      this.setWidgetState();
      this.sessionStorageService.setItem<HotelsState>(this.stateKey, this.widgetState);
      void this.widgetSuggestionService.addSuggestedWidgets({
        data: this.widgetState,
        type: this.widget.widget_id,
        page_url: window.location.href,
        destination_url: this.widget.destination_url
      });

      return true;
    }

    return false;
  }

  placeNameChange(e: Event): void {
    this.placeName = (e.target as HTMLInputElement).value;
  }

  updatePlaceName(value: string): void {
    this.placeName = value;
  }

  // Only Edge hack.
  inputClick(e: InputEvent): void {
    setTimeout(() => {
      if (!(e.target as HTMLInputElement).value) {
        this.placeName = '';
        this.cdr.detectChanges();
      }
    });
  }

  handleRoomsAndGuests(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.showGuestsAndRoomsData = !this.showGuestsAndRoomsData;
  }

  handleRoomsAndGuestsData(roomsEvent: RoomsEvent): void {
    if (this.widget.widget_id === roomsEvent.widgetId) {
      this.roomsData = roomsEvent.roomsData;
      this.roomsAndGuestsTitle = this.hotelRoomsService.getRoomsAndGuestsTitle(roomsEvent.roomsData);
      this.roomFormData = this.hotelRoomsService.getRoomsWrapperData(roomsEvent.roomsData);
    }
  }

  dismissRoomPopover(): void {
    this.showGuestsAndRoomsData = false;
  }

  onDateSelection(date: NgbDateStruct, target: string): void {
    if (target === 'checkIn') {
      this.checkOut = this.datepickerParser.toNgbDate(new Date(this.datepickerParser.format(date)), 3);
      this.checkIn = date;
    } else {
      this.checkOut = date;
    }
  }

  ngOnDestroy(): void {
    this.setWidgetState();
    this.sessionStorageService.setItem<HotelsState>(this.stateKey, this.widgetState);
  }

  private alertMessage(message: string): void {
    const modal = this.modalService.open(ModalAlertComponent, { size: 'sm', centered: true });

    modal.componentInstance.content = { text: message };
  }

  private getWidgetState(): void {
    this.autocompleteSettings.inputString = this.widgetState.placeName;
    this.autocompleteOptions.inputString = this.widgetState.placeName;
    this.placeName = this.widgetState.placeName;
    this.checkIn = this.datepickerParser.toNgbDate(this.datepickerParser.handleTimezoneDiff(this.widgetState.checkIn.toString()));
    this.checkOut = this.datepickerParser.toNgbDate(this.datepickerParser.handleTimezoneDiff(this.widgetState.checkOut.toString()));
    this.roomsData = JSON.parse(this.widgetState.roomsData);
    this.roomsAndGuestsTitle = this.widgetState.roomsAndGuestsTitle;
    this.roomFormData = JSON.parse(this.widgetState.roomFormData);
    this.placeLat = this.widgetState.placeLat;
    this.placeLng = this.widgetState.placeLng;
  }

  private setWidgetState(): void {
    this.widgetState = {
      placeName: this.placeName,
      checkIn: new Date(this.datepickerParser.format(this.checkIn)),
      checkOut: new Date(this.datepickerParser.format(this.checkOut)),
      roomsData: JSON.stringify(this.roomsData),
      roomsAndGuestsTitle: this.roomsAndGuestsTitle,
      roomFormData: JSON.stringify(this.hotelRoomsService.getRoomsWrapperData(this.roomsData)),
      placeLat: this.placeLat,
      placeLng: this.placeLng
    };
  }

  private getOrderedParams(): string {
    const orderedParams = Object.keys(this.activatedRoute.snapshot.queryParams)
      .filter(el => el !== 'tw_city_zip' && el !== 'tw_q')
      .sort()
      .reduce(
        (obj, key) => {
          obj[key] = this.activatedRoute.snapshot.queryParams[key];
          return obj;
        }, {}
      );

    return JSON.stringify(orderedParams);
  }

  updateSpan(isActive: boolean): void {
    this.doubleDotForField = !!(this.placeName || isActive);
  }
}
