import GoogleCalendarEvent from '@shared/models/google-event.model';
import OutlookCalendarEvent from '@shared/models/outlook-event.model';
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnInit
} from '@angular/core';
import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@shared/models/analytics.event.model';
import { AnalyticsService } from '@shared/services/analytics.service';
import { ClickOutService } from '@shared/services/click-out.service';
import { HomepageAdsService } from '@shared/services/homepage-ads.service';
import { ModalAlertComponent } from '@shared/components/modal-alert/modal-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WINDOW } from 'g3-common-ui';
import dayjs from 'dayjs';

@Component({
  selector: 'app-open-enrollment-ad',
  templateUrl: './open-enrollment-ad.component.html',
  styleUrls: ['./open-enrollment-ad.component.less']
})
export class OpenEnrollmentAdComponent implements OnInit, AfterViewInit {

  @Input() public appAutoTabIndex: number;
  @Input() public startDate: Date;
  @Input() public endDate: Date;
  @Input() public sectionName: string;
  @Input() public moreInfo: string;
  @Input() public groupTabIndex: number;

  public anchorLink: string;
  public mouseJustLeft = false;
  public showScheduleSubmenu = false;
  public scheduleSubmenu = [
    {
      icon: '/assets/icons/google.svg',
      title: 'Google',
      click: async (): Promise<void> => this.addEventToGoogleCalendar()
    },
    {
      icon: '/assets/icons/outlook.svg',
      title: 'Outlook',
      click: (): void => this.addEventToOutlookCalendar()
    }
  ];

  constructor(
    private modalService: NgbModal,
    private analyticsService: AnalyticsService,
    private homepageAdsService: HomepageAdsService,
    private clickOutService: ClickOutService,
    @Inject(WINDOW) private window: WINDOW,
  ) { }

  public ngOnInit(): void {
    this.anchorLink = this.homepageAdsService.getAnchorLink(`s_${this.sectionName}`);
  }

  public ngAfterViewInit(): void {
    this.homepageAdsService.scrollToAnchor(this.anchorLink);
  }

  public onMouseLeave(): void {
    this.mouseJustLeft = true;
  }

  public getEnrollmentDates(): string {
    const startDate = dayjs(this.startDate).tz('America/Chicago');
    const endDate = dayjs(this.endDate).tz('America/Chicago');

    const startDateMonth = startDate.format('MMMM');
    const endDateMonth = endDate.format('MMMM');

    const startDateYear = startDate.format('YYYY');
    const endDateYear = endDate.format('YYYY');

    const startDateDay = startDate.format('DD');
    const endDateDay = endDate.format('DD');

    if (startDateMonth !== endDateMonth && startDateYear !== endDateYear) {
      return `${startDateMonth} ${startDateDay}, ${startDateYear} - ${endDateMonth} ${endDateDay}, ${endDateYear}`;
    }

    if (startDateMonth !== endDateMonth) {
      return `${startDateMonth} ${startDateDay} - ${endDateMonth} ${endDateDay}, ${startDateYear}`;
    }

    return `${startDateMonth} ${startDateDay} - ${endDateDay}, ${startDateYear}`;
  }

  public openMoreInfoModal(): void {

    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('enrollment-more-info', {}),
      new AnalyticsGAEventModel('enrollment-more-info', {
        category: 'open-enrollment'
      })
    ]);

    const modal = this.modalService.open(ModalAlertComponent, {
      centered: true,
      windowClass: 'alert-modal'
    });

    modal.componentInstance.content = {
      title: 'Open Enrollment',
      text: this.moreInfo,
      isButton: false,
    };

  }

  public hasLargeSectionName(): boolean {
    return this.sectionName && this.sectionName.length > 20;
  }

  public remindMe(): void {
    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('enrollment-remind-me', {}),
      new AnalyticsGAEventModel('enrollment-remind-me', {
        category: 'open-enrollment'
      })
    ]);

    this.showScheduleSubmenu = !this.showScheduleSubmenu;
  }

  public hideRemindMeMenu(): void {
    if (this.showScheduleSubmenu) {
      this.showScheduleSubmenu = false;
    }
  }

  public async addEventToGoogleCalendar(): Promise<void> {
    const startDateUTC = dayjs(this.startDate);
    const endDateUTC = dayjs(this.startDate).add(1, 'hours');

    const text = encodeURIComponent(`${this.sectionName}: Get ready!`);
    const startDate = startDateUTC.format('YYYYMMDD');
    const endDate = endDateUTC.format('YYYYMMDD');
    const startTime = startDateUTC.format('HHmmss');
    const endTime = endDateUTC.format('HHmmss');
    const details = '';
    const location = encodeURIComponent(window.location.href);

    const googleCalendar = new GoogleCalendarEvent({
      text,
      startDate,
      endDate,
      startTime,
      endTime,
      details,
      location,
    });

    await this.clickOutService.clickOut(googleCalendar.addEvent(), '', {}, true);

    this.hideRemindMeMenu();
  }

  public addEventToOutlookCalendar(): void {
    const dateTimeStart = dayjs(this.startDate);
    const dateTimeEnd = dayjs(this.startDate).add(1, 'hours');

    const filename = 'open-enrollment';
    const dtStamp = dayjs().valueOf();
    const dtStart = dateTimeStart.valueOf();
    const dtEnd = dateTimeEnd.valueOf();
    const summary = `${this.sectionName}: Get ready!`;
    const description = '';
    const location = window.location.href;

    const outlookCalendar = new OutlookCalendarEvent({
      filename,
      dtStamp,
      dtStart,
      dtEnd,
      summary,
      description,
      location
    });

    outlookCalendar.addEvent();

    this.hideRemindMeMenu();
  }

}
