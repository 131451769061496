/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { ActivatedRoute, QueryParamsHandling, Router } from '@angular/router';
import { SafeUrl } from '@angular/platform-browser';
import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@app/shared/models/analytics.event.model';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import intersectionBy from 'lodash-es/intersectionBy';
import {
  UrlHelperService,
  PlatformCountry,
  ImageUrlPipe,
  ImageUrlPipeOptions,
  ImgixCropSettingsService,
  WINDOW,
  KnownUserTypes
} from 'g3-common-ui';

import { ClickOutService } from '@shared/services/click-out.service';
import { DirectClickOutService } from '@shared/services/direct-click-out.service';
import { from, Observable, of, Subject } from 'rxjs';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { ModalExpiredOfferComponent } from '../modal-expired-offer/modal-expired-offer.component';
import { ModalPassThruComponent } from '@app/shared/components/modal-pass-thru/modal-pass-thru.component';
import { ModalPassThruModel } from '@app/shared/components/modal-pass-thru/modal-pass-thru.model';
import { OfferExternalSources, OfferModel, OfferStatus } from '@shared/models/offer.model';
import { OffersAnalyticsService } from '@shared/services/analytics/offers-analytics.service';
import { OffersService } from '@shared/services/offers.service';
import { OfferType } from '@shared/models/offer.type';
import { SingleUseCodeResponse, SingleUseCodeService } from '@shared/components/coupon-modal/single-use-code.service';
import { TodayDeal } from '@app/shared/models/today-deal.model';
import { TodayDealsService } from '@app/shared/services/today-deals.services';
import { TokensService } from '@shared/services/tokens.service';
import { ProfileService } from '@shared/services/profile/profile.service';
import { UserModel } from '@core/auth/services/user.model';
import { ModalService } from '@shared/services/modal.service';
import { KnownUserService } from '@app/shared/services/known-user.service';
import { AccessSettingOption } from '@app/shared/interfaces/search-offer-item.interface';
import { OfferOtpModalComponent } from '../offer-otp-modal/offer-otp-modal.component';
import { SOURCE_ID } from '@app/shared/constants/source-id.constants';
import { ConfigService, VendorSettings } from '@app/shared/services/config.service';
import { PermissionService } from '@app/core/auth/services/permission.service';
import { ModalOfferInvalidLocationComponent } from '../modal-offer-invalid-location/modal-offer-invalid-location.component';
import { COUNTRY_LAST_SESSION_KEY } from '@app/shared/constants/profile.constants';
import { AuthService } from '@app/core/auth/services/auth.service';
import { CjLinksService } from '@app/shared/services/cj-links.service';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';
import {
  AdobeEventNameEnum,
  AdobeEventTypeEnum,
  AdobeImpresssionsEvent,
  AdobeTddImpresssionsEvent,
  AdobeTodayDealData
} from '@app/shared/services/adobe/adobe-analytics.interfaces';
import { ALLOWED_VIEW_OFFERS } from '@app/shared/constants/one-time-password.constants';
import { SessionStorageService } from '@app/widgets/services/session-storage.service';
import { EDITORIAL_CLASS_TITLE, TDD_CLASS_TITLE } from '@app/shared/constants/offer.constants';
import { SearchOfferForAdsInterface } from '@app/offers/services/search-offer-item.interface';
import { PaylogixService } from '@shared/services/paylogix/paylogix.service';

// TODO Rename to ModalPassThruService
@Injectable({
  providedIn: 'root'
})
export class ModalTodayDealService {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private offerService: OffersService,
    private singleUseCodeService: SingleUseCodeService,
    private todayDealsService: TodayDealsService,
    private clickOutService: ClickOutService,
    private analyticsService: AnalyticsService,
    private offersAnalytics: OffersAnalyticsService,
    private tokenService: TokensService,
    private urlHelper: UrlHelperService,
    public directClickOutService: DirectClickOutService,
    private profileService: ProfileService,
    private knownUserService: KnownUserService,
    private configService: ConfigService,
    private paylogixService: PaylogixService,
    private permissionService: PermissionService,
    private readonly authService: AuthService,
    @Inject(WINDOW) private window: WINDOW,
    private readonly cjLinkService: CjLinksService,
    private readonly adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly imageUrlPipe: ImageUrlPipe,
    private readonly imgixCropSettingsService: ImgixCropSettingsService,
  ) { }

  public otpSuccess: Subject<boolean> = new Subject();
  public otpSuccess$: Observable<boolean> = this.otpSuccess.asObservable();

  public handleExpiredOffer(offer: ModalPassThruModel): void {
    const offerGuid = this.route.snapshot.queryParams.off_guid;
    const tddGuid = this.route.snapshot.queryParams.tdd_guid;

    const queryParams = {
      off_guid: offerGuid,
      tdd_guid: tddGuid,
      status: null
    };

    if (offerGuid || tddGuid) {
      queryParams.status = OfferStatus.Expired;
    }

    void this.router.navigate(['/home'], { queryParams });

    if (offer) {
      void this.openOfferUnavailableModal(offer);
    } else {
      void this.openOfferUnavailableModal(
        {
          offerType: queryParams.off_guid ? OfferType.Offer : OfferType.TodayDeal,
          guid: queryParams.off_guid || queryParams.tdd_guid
        } as ModalPassThruModel
      );
    }
  }

  public handleOffer(offer: ModalPassThruModel): void {
    this.sendOfferImpressions(offer);
    void this.increaseOfferView(offer);

    this.getModalCtaPressResult(offer).pipe(
      take(1),
      filter(modalResult => modalResult.ctaPressed)
    ).subscribe(async modalResult => {
      if (modalResult.payload.offerType === OfferType.Offer) {
        const sourceId = sessionStorage.getItem(SOURCE_ID) || '';
        const offerData = modalResult.payload.originalData as OfferModel;
        const clickOutParams = {
          url: offerData.url,
          type: '',
          options: {
            analyticEvents: this.offersAnalytics.getAnalyticsEvents('click-out', offerData),
            guid: offerData.guid
          },
          newTab: offerData.destinationUrlNewTab,
          sourceId,
          sourceBasedParams: offerData.sourceBasedParams
        };

        if (offerData.urlType === 'dynamic') {
          clickOutParams.url = '';
          clickOutParams.type = 'offer_dynamic';
          clickOutParams.options['guid'] = offerData.guid;
        }

        if (this.singleUseCodeService.isExternalUrlWithSucToken(offerData.url, offerData.code)) {
          clickOutParams.options['guid'] = offerData.guid;
        }

        const isCjLink = this.cjLinkService.isCJLink(clickOutParams.url);
        if (isCjLink) {
          void this.cjLinkService.handleCJLink(clickOutParams.url);
          return;
        }

        void this.clickOutService.clickOut(clickOutParams.url, clickOutParams.type, clickOutParams.options, clickOutParams.newTab, sourceId, clickOutParams.sourceBasedParams);

        const adobeData = this.adobeAnalyticsEventsService.getOfferAdobeDataFromOffer(offerData, AdobeEventNameEnum.EMAIL_MODAL, 'offer');

        void this.adobeAnalyticsEventsService.sendOfferClick({
          event_name: AdobeEventNameEnum.EMAIL_MODAL,
          event_type: this.adobeAnalyticsEventsService.getClickOutValueForOffer(offerData),
          properties: adobeData
        });
      } else {
        const tddModel = modalResult.payload.originalData as TodayDeal;

        const isCjLink = this.cjLinkService.isCJLink(modalResult.payload.url);
        if (isCjLink) {
          void this.cjLinkService.handleCJLink(modalResult.payload.url);
          return;
        }

        let clickOutType = null;
        if (tddModel.url_type === 'dynamic') {
          clickOutType = 'offer_dynamic';
        }

        void this.directClickOutService.todayDeals.clickOut(tddModel, false, clickOutType);
        void this.todayDealsService.increaseClickOutCounter(tddModel).toPromise();

        const adobeData = await this.adobeAnalyticsEventsService.getOfferAdobeDataFromTodayDeal(tddModel, AdobeEventNameEnum.EMAIL_MODAL, 'offer');
        if (adobeData.offer.isEbg) {
          delete adobeData.offer.shopping_category;
        }
        delete adobeData.offer.isEbg;

        this.adobeAnalyticsEventsService.emitGeneralEvent({
          event_name: AdobeEventNameEnum.EMAIL_MODAL,
          event_type: this.adobeAnalyticsEventsService.getClickOutValueForTdd(tddModel),
          properties: adobeData
        });
      }
    });
  }

  public open(): void {
    const maxGuids = 10;
    const { off_guid: offGuid, tdd_guid: tddGuid, status } = this.route.snapshot.queryParams;

    if ((offGuid && tddGuid) || !(offGuid || tddGuid)) {
      // don't show modal if both parameters are present
      return;
    }

    const guids = (offGuid ? offGuid : tddGuid).split(',').slice(0, maxGuids);
    const data$ = offGuid ? this.getOfferFromArray(guids) : this.getTodayDealFromArray(guids);

    data$.pipe(
      catchError(err => {
        console.error(err);
        return from([{ originalData: {} }]);
      }),
      take(1),
      map((offer: any) => {
        if (status === OfferStatus.Demo) {
          offer.status = OfferStatus.Active;
        }
        return offer;
      })
    ).subscribe(async (offer: ModalPassThruModel) => {
      const isTodayDealActive = offGuid ? true : offer.endDate && new Date(offer.endDate) > new Date();

      const offerData = offer.originalData as OfferModel;

      if (offerData?.is_widget_iframe) {
        // * Navigate user to details page in order to use widget
        void this.router.navigate([`/offers/${offerData.guid}`]);
        return;
      }

      if (await this.isBlockedByAccess(offerData, offer)) {
        return;
      }

      if (offerData?.external_source === OfferExternalSources.Paylogix) {
        if (!this.paylogixService.isOfferMatchMarketplaceOrgId(offerData) && !this.paylogixService.userHasPaylogixId()) {
          this.handleExpiredOffer(offer);
          return;
        }
      }

      if (offer.status === OfferStatus.Active && isTodayDealActive) {
        let flag = false;

        if (this.modalService.hasOpenModal()) {
          this.modalService.activeModalSubject.pipe(
            filter((modal) => !modal),
            filter(() => !flag)
          ).subscribe(() => {
            this.handleOffer(offer);
            flag = true;
          });
        } else {
          this.handleOffer(offer);
        }
      } else {
        this.handleExpiredOffer(offer);
      }

    });
  }

  public async openExpiredOfferModal(guid: string, removeParams?: boolean): Promise<void> {
    this.handleExpiredOffer(null);

    await this.expiredOfferRedirect(guid, removeParams);
  }

  public getOffersObservable({
    ids,
    onlyActive = false,
    onlyAvailable = true,
    excludeClasses = [],
    includeClasses = []
  }: {
    ids: string[];
    onlyActive?: boolean;
    onlyAvailable?: boolean;
    excludeClasses?: string[];
    includeClasses?: string[];
  }): any {
    return this.profileService.profileData$.pipe(
      filter((profileData: UserModel) => Boolean(profileData.countryLast)),
      map((profileData: UserModel) => profileData.countryLast),
      switchMap(countryLast => from(this.offerService.getOffersByIds({
        ids,
        onlyActive,
        onlyAvailable,
        excludeClasses,
        includeClasses,
        country: countryLast
      }))),
    );
  }

  public getOfferFromArray(guids: string[]): any {
    const offers$ = this.getOffersObservable({
      ids: guids,
      excludeClasses: [EDITORIAL_CLASS_TITLE, TDD_CLASS_TITLE]
    });

    return this.getItemFromArraySubscription(offers$, guids).pipe(
      switchMap(offer => this.getOfferData(offer.guid)),
    );
  }

  public getTodayDealFromArray(guids: string[]): any {
    const offers$ = this.getOffersObservable({
      ids: guids,
      includeClasses: [TDD_CLASS_TITLE]
    });

    return this.getItemFromArraySubscription<SearchOfferForAdsInterface>(offers$, guids).pipe(
      map(offerTdd => this.todayDealDataMapper(offerTdd)),
    );
  }

  public getItemFromArraySubscription<T extends { guid?: string }>(observable$: Observable<T[]>, guids: string[]): Observable<T> {
    return observable$.pipe(
      take(1),
      tap(data => {
        if (!data.length) {
          this.handleExpiredOffer(null);
        }
      }),
      filter(items => !!items.length),
      map(items => {
        const itemsMap = new Map();
        items.forEach(item => itemsMap.set(item.guid, item));
        const orderedItems = guids.map(guid => itemsMap.get(guid)).filter(item => !!item);
        const firstActiveItem = orderedItems.find(item => item.status === OfferStatus.Active);
        return firstActiveItem || orderedItems[0];
      })
    );
  }

  public getOfferData(guid: string): Observable<ModalPassThruModel> {
    const offer$ = this.offerService.getOffer(guid, 0, false, EDITORIAL_CLASS_TITLE) as Observable<OfferModel>;

    return offer$.pipe(
      map(offerResponse => new OfferModel(offerResponse)),
      switchMap((offer: OfferModel) => {
        const isSucOffer = this.offerService.isOfferIncludeCode(offer)
          && this.singleUseCodeService.containsSucCode(offer.code);
        const isUrlWithSucToken = this.offerService.isOfferIncludeCode(offer)
          && this.singleUseCodeService.isExternalUrlWithSucToken(offer.url, offer.code);

        let code$: Observable<SingleUseCodeResponse> = null;
        if (isUrlWithSucToken) {
          code$ = of<SingleUseCodeResponse>({ code: null });
        } else if (isSucOffer) {
          code$ = this.singleUseCodeService.getCode(offer.guid);
        } else {
          code$ = of<SingleUseCodeResponse>({ code: offer.code });
        }

        return code$.pipe(
          map((codeResponse: SingleUseCodeResponse) => this.offerDataMapper(offer, codeResponse))
        );
      })
    );
  }

  public async expiredOfferRedirect(guid: string, removeParams?: boolean): Promise<void> {
    const queryParams = {
      queryParams: {
        off_guid: guid,
        status: OfferStatus.Expired
      }
    };

    const clearParams = {
      queryParams: {
        off_guid: null,
        status: null,
      },
      queryParamsHandling: 'merge' as QueryParamsHandling
    };

    const navigationOptions = removeParams
      ? clearParams
      : queryParams;

    await this.router.navigate(['/home'], navigationOptions);
  }

  public async isBlockedByAccess(offer: OfferModel, passThru: ModalPassThruModel): Promise<boolean> {
    const { isBlockedForGuest, isBlockedForKnown, isBlockedForBoth, allowedForChild } = this.checkRestrictions(offer);
    const isPaylogixAllowed = this.configService.getIsPaylogixAllowed();

    if (isBlockedForGuest || isBlockedForBoth) {
      await this.openExpiredOfferModal(offer.guid, true);
      return true;
    }

    if (isBlockedForKnown && !allowedForChild) {
      this.openOfferOtpModal(passThru.guid);
      return true;
    }

    if (!isPaylogixAllowed && offer?.external_source?.toLowerCase() === 'paylogix') {
      await this.openExpiredOfferModal(offer.guid, true);
      return true;
    }

    return false;
  }

  public checkRestrictions(offerData: OfferModel): { isBlockedForGuest: boolean; isBlockedForKnown: boolean; isBlockedForBoth: boolean; allowedForChild: boolean } {
    const knownRestrictedTypes = [
      AccessSettingOption.PromptForAuth,
      AccessSettingOption.HiddenToGuestKnown
    ];
    const guestRestrictedTypes = [
      AccessSettingOption.PromptForAuth,
      AccessSettingOption.HiddenToGuest,
      AccessSettingOption.HiddenToGuestKnown
    ];

    const knownType = this.knownUserService.knowUserType;
    const isBlockedForKnown = knownType && knownRestrictedTypes.includes(offerData.access_setting);

    const isGuest = this.permissionService.hasDefined('guest:access');
    const isBlockedForGuest = isGuest && guestRestrictedTypes.includes(offerData.access_setting);

    const isBlockedForBoth = (isGuest || knownType && knownType !== KnownUserTypes.SET_CONFIRMED)
      && offerData.access_setting === AccessSettingOption.HiddenToGuestKnown;

    const allowedForChild = this.isAllowedToViewOffer(offerData.guid);

    return {
      isBlockedForGuest,
      isBlockedForKnown,
      isBlockedForBoth,
      allowedForChild
    };
  }

  public openOfferOtpModal(guid: string): void {
    const otpCompleted = new EventEmitter<boolean>();

    this.modalService.createModal({
      component: OfferOtpModalComponent,
      attributes: [
        {
          key: 'offerGuid',
          value: guid,
        },
        {
          key: 'otpCompleted',
          value: otpCompleted
        }
      ],
      options: {
        backdrop: 'static',
        windowClass: 'offer-otp-modal',
        animation: false
      }
    });

    otpCompleted.pipe(take(1)).subscribe(() => {
      this.otpSuccess.next(true);
    });
  }

  public isAllowedToViewOffer(guid: string): boolean {
    const allowedOffers = this.sessionStorageService.getItem<string[]>(ALLOWED_VIEW_OFFERS) || [];
    const knownType = this.knownUserService.knowUserType;

    return knownType?.includes('child') && allowedOffers?.includes(guid);
  }

  private async openOfferUnavailableModal(offer: ModalPassThruModel): Promise<void> {
    let offerExists = true;
    let offerStatus = null;
    try {
      if (offer.guid) {
        const offerData = await this.offerService.getOffer(offer.guid);
        offerStatus = offerData.status;
      }
    } catch (err) {
      offerExists = false;
    }

    let offerAvailableCountries: PlatformCountry[] = [];

    try {
      offerAvailableCountries = await this.offerService.getOfferAvailableCountries(offer.guid);
    } catch (err) {
      offerAvailableCountries = [];
    }

    const marketplaceAvailableCountries = this.configService.getOption<PlatformCountry[]>('allowed_countries', []);
    const availableCountries: PlatformCountry[] = intersectionBy(offerAvailableCountries, marketplaceAvailableCountries, 'country_code');

    this.profileService.profileData$.pipe(filter(data => !!data.guid), take(1)).subscribe(profile => {
      const currentUserCountryCode = profile.countryLast;
      const isOfferUnavailableBecauseOfLocation = offerExists &&
        offerStatus === OfferStatus.Active &&
        availableCountries.length &&
        !availableCountries.map(c => c.country_code).find(code => code === currentUserCountryCode);

      if (isOfferUnavailableBecauseOfLocation) {
        this.openOfferUnavailableLocationModal(availableCountries);
      } else {
        this.openOfferExpiredModal(offer);
      }
    });
  }

  private openOfferUnavailableLocationModal(availableCountries: PlatformCountry[]): void {
    this.modalService.createModal({
      component: ModalOfferInvalidLocationComponent,
      attributes: [
        { key: 'availableCountries', value: availableCountries },
        { key: 'onSwitchLocation', value: async (country: PlatformCountry): Promise<void> => this.switchLocation(country) }
      ],
      options: {
        windowClass: 'modal-offer-unavailable-location',
        centered: true,
      }
    });
  }

  private openOfferExpiredModal(offer: ModalPassThruModel): void {
    const eventData = {
      type: offer.offerType === OfferType.Offer ? 'off' : 'tdd',
      guid: offer.guid
    };
    const label = `${eventData.type} | ${eventData.guid}`;
    const openAnalyticsEvents = [
      new AnalyticsInternalEventModel('expired-modal-show', eventData),
      new AnalyticsGAEventModel('expired-modal-show', { category: 'expired-modal', label })
    ];
    const dismissAnalyticsEvents = [
      new AnalyticsInternalEventModel('expired-modal-dismiss', eventData),
      new AnalyticsGAEventModel('expired-modal-dismiss', { category: 'expired-modal', label })
    ];
    this.analyticsService.eventsTrack(openAnalyticsEvents);
    this.modalService.createModal({
      component: ModalExpiredOfferComponent,
      options: {
        windowClass: 'modal-expired-offer',
        centered: true,
        beforeDismiss: (): boolean => {
          this.analyticsService.eventsTrack(dismissAnalyticsEvents);
          return true;
        }
      }
    });
  }

  private async switchLocation(country: PlatformCountry): Promise<void> {
    await this.profileService.updateCurrentCountry(country.country_code, false);
    this.window.location.reload();
  }

  private getModalCtaPressResult(offer: ModalPassThruModel): Observable<{ ctaPressed: boolean; payload: ModalPassThruModel }> {
    const modal = this.modalService.openModal({
      component: ModalPassThruComponent,
      attributes: [{
        key: 'item',
        value: offer
      }],
      options: {
        windowClass: 'today-deal-modal',
        centered: true
      }
    });

    return from(modal.result).pipe(
      catchError(() => of(false)),
      map((ctaPressed: boolean) => ({
        ctaPressed,
        payload: offer
      }))
    );
  }

  private offerDataMapper(offer: OfferModel, codeResponse: SingleUseCodeResponse): ModalPassThruModel {
    const brandNameOrOtherText = offer.vendorBrand?.title ?? '';
    const brandName = brandNameOrOtherText ? `${brandNameOrOtherText}: ` : '';
    const header = `${brandName}${offer.subtitle}`;

    let vendorLogoUrl: string | SafeUrl = offer.vendorBrand?.logoSquare;
    if (!vendorLogoUrl) {
      const options: ImageUrlPipeOptions = this.imgixCropSettingsService.getImgixDisplaySettings(offer.search_image_imgix_settings, {
        w: 104, h: 104, alternate: true
      });
      vendorLogoUrl = this.imageUrlPipe.transform(offer.search_image_url, options, false);
    }

    return {
      header,
      vendorLogoUrl,
      brand: offer.vendorBrand?.title || offer.vendor_other_brand_name || '',
      code: codeResponse.code,
      url: offer.url,
      offerType: OfferType.Offer,
      originalData: offer,
      status: offer.status,
      guid: offer.guid,
      isInternal: !this.urlHelper.isExternalUrl(offer.url) || this.tokenService.isTokenPresent(offer.url, 'ebghost')
    };
  }

  private todayDealDataMapper(offerTdd: SearchOfferForAdsInterface | TodayDeal): ModalPassThruModel {
    const todayDeal = new TodayDeal(offerTdd);
    return {
      header: todayDeal.headline,
      vendorLogoUrl: todayDeal.image_url,
      brand: todayDeal.company_name,
      code: todayDeal.coupon_code,
      url: todayDeal.url,
      offerType: OfferType.TodayDeal,
      originalData: todayDeal,
      status: todayDeal.status,
      guid: todayDeal.guid,
      endDate: todayDeal.end_date,
      isInternal: todayDeal.is_ebg || !this.urlHelper.isExternalUrl(todayDeal.url)
    };
  }

  private async sendOfferImpressions(offer: ModalPassThruModel): Promise<void> {
    if (offer.offerType === OfferType.Offer) {
      const offerData = offer.originalData as OfferModel;
      const adobeEvent: AdobeImpresssionsEvent = {
        event_name: AdobeEventNameEnum.EMAIL_MODAL,
        event_type: AdobeEventTypeEnum.IMPRESSION,
        properties: {
          impressions: [this.adobeAnalyticsEventsService.getOfferAdobeDataFromOffer(offerData, AdobeEventNameEnum.EMAIL_MODAL, 'offer')],
        }
      };
      void this.adobeAnalyticsEventsService.sendOfferImpressions(adobeEvent);
    } else {
      const tddData = offer.originalData as TodayDeal;
      const adobeData = await this.adobeAnalyticsEventsService.getOfferAdobeDataFromTodayDeal(tddData, AdobeEventNameEnum.EMAIL_MODAL, 'offer');
      if (adobeData.offer.isEbg) {
        delete adobeData.offer.shopping_category;
      }
      delete adobeData.offer.isEbg;
      const adobeEvent: AdobeTddImpresssionsEvent = {
        event_name: AdobeEventNameEnum.EMAIL_MODAL,
        event_type: AdobeEventTypeEnum.IMPRESSION,
        properties: {
          offer: adobeData.offer as unknown as AdobeTodayDealData,
        }
      };
      void this.adobeAnalyticsEventsService.emitGeneralEvent(adobeEvent);
    }
  }

  private async increaseOfferView(offer: ModalPassThruModel): Promise<void> {
    if (offer.offerType === OfferType.TodayDeal) {
      await this.todayDealsService.increaseViewCounter((offer.originalData as TodayDeal)).toPromise();
    } else if (offer.offerType === OfferType.Offer) {
      await this.offerService.increaseOfferViewCount(offer.guid);
    }
  }

}
