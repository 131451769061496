import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';
import { AUSTIN_COORDINATES } from '@app/widgets/interfaces/autocomplete-settings.interface';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { LatLngBounds } from 'ngx-google-places-autocomplete/objects/latLngBounds';

const defaultLocation = AUSTIN_COORDINATES;
const defaultBounds = {
  north: defaultLocation[0] + 0.1,
  south: defaultLocation[0] - 0.1,
  east: defaultLocation[1] + 0.1,
  west: defaultLocation[1] - 0.1,
};
const defaultSettings: Partial<Options> = {
  bounds: defaultBounds as unknown as LatLngBounds,
  fields: ['address_components', 'geometry', 'icon', 'name'],
  strictBounds: false,
  types: ['(regions)'],
};

@Component({
  selector: 'app-google-places-autocomplete',
  templateUrl: './google-places-autocomplete.component.html',
  styleUrls: ['./google-places-autocomplete.component.less'],
})
export class GooglePlacesAutocompleteComponent {

  @Input() public settings: Partial<Options> = defaultSettings;
  @Input() public placeholder = '';
  @Input() public wideFormat = false;
  @Input() public autocompleteOptions: { inputString?: string } = {};
  @Input() public normalizedDestination = true;
  @Input() public classes = '';

  @Output() public destinationChange: EventEmitter<string> = new EventEmitter();
  @Output() public addressChange: EventEmitter<Address | string> = new EventEmitter();
  @Output() public isInputActive = new EventEmitter<boolean>();

  @ViewChild('placesRef') public placesRef: GooglePlaceDirective;

  public destination: string | Address = '';

  public onDestinationChange(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    const target = event.target as HTMLTextAreaElement;
    this.destinationChange.emit(target.value);
  }

  public handleAddressChange(address: Address): void {
    this.destination = this.normalizedDestination
      ? this.normalizeDestination(address.address_components)
      : address;

    this.addressChange.emit(this.destination);
  }

  public clearInput(event: Event): void {
    (event.target as HTMLTextAreaElement).value = '';
  }

  private normalizeDestination(addressComponents: AddressComponent[]): string {
    if (!addressComponents) {
      return '';
    }

    return addressComponents
      .filter(el => !el.types.includes('administrative_area_level_2'))
      .map(el => el.short_name)
      .join(', ');
  }

  public onFocus(): void {
    this.isInputActive.emit(true);
  }

  public onBlur(): void {
    this.isInputActive.emit(false);
  }
}
