/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Ad } from '@shared/models/ad.model';
import { AdCtaClick } from '@shared/components/ad-item/ad-item.component';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import { ConfigService, EnrollmentBuildUpInfo } from '@shared/services/config.service';
import { DirectClickOutService } from '@shared/services/direct-click-out.service';
import get from 'lodash-es/get';
import { HomepageAdsService } from '@app/shared/services/homepage-ads.service';
import { PRODUCT_CARD } from '@app/zones/services/zones.service';
import { WindowHelper } from 'g3-common-ui';
import { ZoneHeaderService } from '@zones/services/zone-header.service';
import { ZoneSectionElement } from '@zones/interfaces/zones-search-response.interface';
import { ZonesSectionElementsAnalyticsService } from '@zones/services/analytics/zones-section-elements-analytics.service';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';
import {
  AdobeEventLocation,
  AdobeEventNameEnum,
  AdobeEventTypeEnum,
  AdobeImpresssionsEvent
} from '@app/shared/services/adobe/adobe-analytics.interfaces';

@Component({
  selector: 'app-cards-product',
  templateUrl: './cards-product.component.html',
  styleUrls: ['./cards-product.component.less']
})
export class CardsProductComponent implements OnInit, AfterViewInit {

  @Input() data: ZoneSectionElement;
  @Input() wideContainerClass = '';
  @Input() wideContainerClassName = '';
  @Input() groupTabIndex: number;
  @Input() hideGhostEffect = false;
  @Input() isDesktop = true;
  @Input() isPinnedZone = false;
  @Input() cardsProductElement: ElementRef;
  @Input() userCountry: string;

  @Output() displayed = new EventEmitter<ZoneSectionElement>();
  @Output() initialized = new EventEmitter<ZoneSectionElement>();

  @ViewChild('cardsProduct') cardsProduct: ElementRef;

  hideArrows = false;
  hovered = false;
  processingSwipe = false;
  processingScroll = false;
  anchorLink = '';
  isCtaMergedToCards = false;
  isProductCards = true;

  constructor(
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    private directClickOutService: DirectClickOutService,
    private zonesSectionsAnalyticsService: ZonesSectionElementsAnalyticsService,
    private analyticsService: AnalyticsService,
    private configService: ConfigService,
    private zoneHeaderService: ZoneHeaderService,
    private homepageAdsService: HomepageAdsService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const target = event.target as Window;
    const adsLength = get(this.data, 'ads.length', 0);
    this.hideArrows = target.innerWidth >= WindowHelper.SIZE_M && target.innerWidth < WindowHelper.SIZE_L && adsLength === 4 || target.innerWidth >= WindowHelper.SIZE_L && adsLength === 5;
  }

  get isEnoughAds(): boolean {
    return this.data && this.ads.length >= this.data.min_units;
  }

  get ads(): Ad[] {
    return (this.data && this.data.ads ? this.data.ads : []).filter(i => i.dbImageUrl);
  }

  get isEnrollment(): boolean {
    const enrollmentInfo = this.getEnrollmentInfo();

    return this.data.open_enrollment && enrollmentInfo && !!enrollmentInfo.section_name && enrollmentInfo.countries.includes(this.userCountry);
  }

  ngOnInit(): void {
    this.data.double_wide = false;
    this.handleZoneElement();
  }

  ngAfterViewInit(): void {
    if (this.data) {
      // * Disabled by G3PROJ-2529
      // if (this.isEnoughAds) {
      //   this.sendAdobeImpressions();
      // }
      this.displayed.emit(this.data);
    }
    this.handleProductCTAView();
    this.homepageAdsService.scrollToAnchor(this.anchorLink);
  }

  sendAdobeImpressions(): void {
    const adobeEvent: AdobeImpresssionsEvent = {
      event_name: AdobeEventNameEnum.CARD,
      event_type: AdobeEventTypeEnum.IMPRESSION,
      properties: {
        impressions: this.ads.map((ad, index) => {
          const size = this.isDoubleWideCard(index) ? 'double' : 'standard';
          return this.adobeAnalyticsEventsService.getOfferAdobeData(ad, size, `offer${index + 1}`, this.data.name);
        })
      }
    };
    void this.adobeAnalyticsEventsService.sendOfferImpressions(adobeEvent, AdobeEventLocation.Homepage);
  }

  isProductCard(ad: Ad): boolean {
    return ad.class && ad.class === PRODUCT_CARD;
  }

  async onScrollEvent(direction: string): Promise<void> {
    if (this.processingScroll) {
      return;
    }
  }

  addItemTrackBy(index, item): any {
    return item.id;
  }

  isWideContainer(): boolean {
    return !!this.wideContainerClass && this.wideContainerClass === this.wideContainerClassName;
  }

  showAllClickHandler(): void {
    this.zoneHeaderService.headerClick(this.data.view_all_url, this.data.is_ebg);
  }

  handleAdClick(ad: Ad, index: number): void {
    const adobeInfo = this.adobeAnalyticsEventsService.getAdobeClickInfo(ad, this.isDoubleWideCard(index) ? 'double' : 'standard', this.data.name);
    void this.directClickOutService.adCards.handleAdClick(ad, 'card', this.data, undefined, undefined, undefined, undefined, adobeInfo);
  }

  isDoubleWideCard(index: number): boolean {
    return index === 0 && this.data.double_wide && !this.isEnrollment;
  }

  onClickedAdCta(data: AdCtaClick, index: number): void {
    const adobeInfo = this.adobeAnalyticsEventsService.getAdobeClickInfo(data.ad, this.isDoubleWideCard(index) ? 'double' : 'standard', this.data.name);

    switch (data.source) {
      case 'cta':
        void this.directClickOutService.adCards.handleAdClick(data.ad, 'cta', this.data, undefined, undefined, undefined, undefined, adobeInfo);
        break;
      case 'cta-zipcode':
        void this.directClickOutService.adCards.handleAdClick(data.ad, 'cta-zipcode', this.data, 'offer-card-with-zipcode-input', '', data.params, undefined, adobeInfo);
        break;
    }
  }

  onDisplayedAd(ad: Ad): void {
    this.analyticsService.eventsTrack(this.zonesSectionsAnalyticsService.items.getAnalyticsEvents('view', this.data, ad));
  }

  getEnrollmentInfo(): EnrollmentBuildUpInfo {
    return this.configService.controls.enrollment_buildup;
  }

  private handleProductCTAView(): void {
    const cardsProductElement = document.querySelector('app-cards-product');
    setTimeout(() => {
      if (cardsProductElement) {
        this.isCtaMergedToCards = cardsProductElement.previousElementSibling
          && cardsProductElement.previousElementSibling.classList.contains('cta-product') && !(this.data && this.data.name) && !this.isPinnedZone;
      }
    });
  }

  private handleZoneElement(): void {
    if (this.data) {
      const adsLength = get(this.data, 'ads.length', 0);
      this.hideArrows = this.isPinnedZone && (window.innerWidth >= WindowHelper.SIZE_M && window.innerWidth < WindowHelper.SIZE_L && adsLength === 4)
        || (window.innerWidth >= WindowHelper.SIZE_L && adsLength === 5);
      this.initialized.emit(this.data);

      if (this.data.name) {
        const sectionName = `s_${this.data.name}`;
        this.anchorLink = this.homepageAdsService.getAnchorLink(sectionName);
      } else {
        this.anchorLink = 's_cards_scroll';
      }
    }
  }
}
