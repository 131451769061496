import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Ad } from '@app/shared/models/ad.model';
import get from 'lodash-es/get';
import { HomepageAdsService } from '@app/shared/services/homepage-ads.service';
import { ZoneSectionElement } from '@app/zones/interfaces/zones-search-response.interface';
import { WindowHelper } from 'g3-common-ui';
import { ZonesService } from '@app/zones/services/zones.service';
import { FilterStateService } from '@app/shared/services/search/filter-state.service';
import { Router } from '@angular/router';
import { SearchService } from '@app/shared/services/search/search.service';
import { ZoneHeaderService } from '@app/zones/services/zone-header.service';

@Component({
  selector: 'app-top-brands',
  templateUrl: './top-brands.component.html',
  styleUrls: ['./top-brands.component.less']
})
export class TopBrandsComponent implements OnInit, AfterViewInit {

  @Input() public zoneElement: ZoneSectionElement;
  @Input() public wideContainerClass = '';
  @Input() public wideContainerClassName = '';
  @Input() public groupTabIndex: number;
  @Input() public isPinnedZone = false;

  @Output() public displayed = new EventEmitter<ZoneSectionElement>();
  @Output() public initialized = new EventEmitter<ZoneSectionElement>();
  @Output() public clickedItem = new EventEmitter<Ad>();

  public topBrands: Ad[];
  public hideArrows = false;

  public hovered = true;
  public showSection = true;
  public anchorLink = '';
  public isDesktop = false;
  public hideGhostEffect = false;

  public constructor(
    private homepageAdsService: HomepageAdsService,
    private readonly zonesService: ZonesService,
    private readonly filterStateService: FilterStateService,
    private readonly router: Router,
    private readonly searchService: SearchService,
    private readonly zoneHeaderService: ZoneHeaderService,
  ) { }

  @HostListener('window:resize', ['$event'])
  public onResize(event: Event): void {
    const target = event.target as Window;
    const adsLength = this.topBrands.length;

    this.hideArrows = target.innerWidth >= WindowHelper.SIZE_M
      && target.innerWidth < WindowHelper.SIZE_L && adsLength === 4
      || target.innerWidth >= WindowHelper.SIZE_L && adsLength === 5;
  }

  public async ngOnInit(): Promise<void> {
    await this.getTopBrands();

    if (this.zoneElement) {
      this.initZoneContent();
    }
  }

  public ngAfterViewInit(): void {
    if (this.zoneElement) {
      this.displayed.emit(this.zoneElement);
    }

    this.homepageAdsService.scrollToAnchor(this.anchorLink);
  }


  public onBrandClick(brand: Ad, event: Event): void {
    if (event) {
      event.preventDefault();
    }

    this.clickedItem.emit(brand);

    const content = brand.content as unknown as { hasDetailsPage: boolean; guid: string };
    if (content?.hasDetailsPage) {
      void this.router.navigate(['/offers', content.guid]);
      return;
    }

    const queryParams = {
      b: encodeURIComponent(brand.guid)
    };

    this.searchService.filter = {};
    this.searchService.search('', { vendor: [brand.guid] }, false);
    void this.router.navigate(['/offers'], { queryParams });

    this.filterStateService.emitSearchEvent();
  }

  public isWideContainer(): boolean {
    return this.wideContainerClass && this.wideContainerClass === this.wideContainerClassName;
  }

  public handleBrokenImageUrl($event: Event, title: string): void {
    this.topBrands = this.topBrands.filter(brand => brand.title !== title);
  }

  public showAllClickHandler(url: string): void {
    this.zoneHeaderService.headerClick(url, this.zoneElement.is_ebg);
  }

  public trackByBrandId(index: number, item: Ad): string {
    return item.id;
  }

  private async getTopBrands(): Promise<void> {
    this.topBrands = await this.homepageAdsService.getTopBrands();

    if (this.zoneElement.min_units > this.topBrands?.length) {
      this.topBrands = [];
    }

    if (this.zoneElement.max_units < this.topBrands?.length) {
      this.topBrands = this.topBrands.slice(0, this.zoneElement.max_units);
    }
  }

  private initZoneContent(): void {
    const adsLength = get(this.zoneElement, 'ads.length', 0);
    this.hideArrows = this.isPinnedZone && (window.innerWidth >= WindowHelper.SIZE_M && window.innerWidth < WindowHelper.SIZE_L && adsLength === 4)
      || (window.innerWidth >= WindowHelper.SIZE_L && adsLength === 5);
    this.initialized.emit(this.zoneElement);

    if (this.zoneElement.name) {
      const sectionName = `s_${this.zoneElement.name}`;
      this.anchorLink = this.homepageAdsService.getAnchorLink(sectionName);
    } else {
      this.anchorLink = 's_cards_scroll';
    }
  }
}
