import { Injectable } from '@angular/core';
import { PermissionService } from '@app/core/auth/services/permission.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, takeWhile, delay, take, switchMap } from 'rxjs/operators';
import shuffle from 'lodash-es/shuffle';
import first from 'lodash-es/first';
import { ModalService } from '@shared/services/modal.service';
import { KnownUserService } from '@shared/services/known-user.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { OneTimePasswordService } from './one-time-password.service';
import { ProfileService } from '@shared/services/profile/profile.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@shared/models/analytics.event.model';
import { AuthService } from '@app/core/auth/services/auth.service';
import { FinalStepTypes } from '../enums/confirmation-dropdown.enum';
import { MregNotification } from '../interfaces/mreg-config.interface';
import { ConfirmationHeaderContent } from '../interfaces/confirmation.interface';
import { MREG_ENTRY_DATE } from '../constants/mreg.constants';
import { SessionStorageService } from '@app/widgets/services/session-storage.service';
import { OTP_SENT_KEY } from '../constants/one-time-password.constants';
import { OtpReasonTypes, OtpTypes, ACCOUNT_ENROLL_TYPES } from 'g3-common-ui';
import { OtpCode, OtpData } from '../interfaces/otp.interface';
import { PaylogixService } from './paylogix/paylogix.service';
import { AccountConfirmationStep } from './account-confirmation.interface';
import { ConfigService } from './config.service';
import { AdobeAnalyticsEventsService } from '@shared/services/adobe/units/adobe-analytics-events.service';

enum AnalitycEvent {
  SignUpDropdownDismissal = 'sign-up-dropdown-dismissal',
  SignUpDropdownFailure = 'sign-up-dropdown-failure',
  SignUpConfirmationSuccessDLK = 'sign-up-confirmation-success-dlk',
  SignUpConfirmationSuccessOTP = 'sign-up-confirmation-success-otp'
}

export const TRANSITION_TIME = 150;
const ACCESS_TOKEN_KEY = 'access_token';
const SHORT_TOKEN_KEY = 'short_token';
const FINAL_STEP_KEY = 'final_step';
const TRACK_OTP_AUTH = 'track_otp_auth';

interface AccountConfirmationState {
  step: AccountConfirmationStep;
  accountConfirmationOpen: boolean;
  email: string;
  guid: string;
  passcode: string;
  codeTimer: number;
  emailEditable: boolean;
  errorMessage: string;
  password: string;
  zip: string;
  phone: string;
  finalStepType: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountConfirmationService {

  public notificationQueue: MregNotification[] = [];
  public isModalClosed = true;
  public isMregHandled = false;
  public confirmationShown = false;

  public allowProfilePrompts: AccountConfirmationStep[] = [];
  public profilePromptsQueue: AccountConfirmationStep[] = [];

  public promptContentSource: BehaviorSubject<ConfirmationHeaderContent> = new BehaviorSubject({} as ConfirmationHeaderContent);
  public promptContent$ = this.promptContentSource.asObservable();

  public isLoginWithoutPassword = false;

  private _state$ = new BehaviorSubject<AccountConfirmationState>({
    step: AccountConfirmationStep.EmailConfirmation,
    accountConfirmationOpen: false,
    email: '',
    guid: '',
    passcode: '',
    codeTimer: 0,
    emailEditable: false,
    errorMessage: '',
    password: '',
    zip: '',
    phone: '',

    // Final step Data
    finalStepType: ''
  });

  get step$(): Observable<AccountConfirmationStep> {
    return this._select(state => state.step);
  }

  get accountConfirmationOpen$(): Observable<boolean> {
    return this._select(state => state.accountConfirmationOpen);
  }

  get email$(): Observable<string> {
    return this._select(state => state.email);
  }

  get passcode$(): Observable<string> {
    return this._select(state => state.passcode);
  }

  get codeTimer$(): Observable<number> {
    return this._select(state => state.codeTimer);
  }

  get emailEditable$(): Observable<boolean> {
    return this._select(state => state.emailEditable);
  }

  get password$(): Observable<string> {
    return this._select(state => state.password);
  }

  get zip$(): Observable<string> {
    return this._select(state => state.zip);
  }

  get phone$(): Observable<string> {
    return this._select(state => state.phone);
  }

  get errorMessage$(): Observable<string> {
    return this._select(state => state.errorMessage);
  }

  get finalStepType$(): Observable<string> {
    return this._select(state => state.finalStepType);
  }

  get state(): AccountConfirmationState {
    return this._state$.getValue();
  }

  get confirmationWrapperState(): Observable<string> {
    return this._confirmationWrapperState;
  }

  get knownUserType(): string {
    return this.knownUserService.knowUserType;
  }

  private _interval: ReturnType<typeof setTimeout>;
  private awaitingFinalization = false;
  private _confirmationWrapperState = new BehaviorSubject('*');

  constructor(
    private permissionService: PermissionService,
    private knownUserService: KnownUserService,
    private oneTimePasswordService: OneTimePasswordService,
    private profileService: ProfileService,
    private modalService: ModalService,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private sessionStorageService: SessionStorageService,
    private paylogixService: PaylogixService,
    private configService: ConfigService,
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
  ) {}

  initialize(): void {
    this.isLoginWithoutPassword = this.configService.getOption('is_allow_login_without_password', false);

    const skipByAccessSettings = this.isLoginWithoutPassword
      && this.knownUserService.knowUserType?.includes('not_set')
      || false;

    if (skipByAccessSettings) {
      this.knownUserService.setKnownSkipPassword(skipByAccessSettings);
    }

    this.modalService.activeModalSubject.subscribe(modal => this.onModalOpen(modal));
    const isSetPassword = this.isUserKnownAndNotSet() && !this.knownUserService.isKnownSkipPassword;

    if (isSetPassword) {
      this._setState({
        step: AccountConfirmationStep.SetPassword
      });
    }

    if (this.knownUserService.knowUserEmail) {
      this._setState({
        email: this.knownUserService.knowUserEmail,
        guid: this.knownUserService.knowUserGuid
      });
    } else {
      void this.profileService.profileData$.subscribe(profile => {
        this._setState({
          email: profile.email,
          guid: profile.guid,
          zip: profile.zipCode,
          phone: profile.unconfirmedPhone?.number
        });
      });
    }
  }

  setConfirmationWrapperState(status: string): void {
    this._confirmationWrapperState.next(status);
  }

  isUserKnownAndUnconfirmed(): boolean {
    return ['known_set_unconfirmed', 'known_not_set_unconfirmed'].includes(this.knownUserService.knowUserType);
  }

  isUserKnownAndNotSet(): boolean {
    return ['known_not_set_confirmed', 'known_not_set_unconfirmed'].includes(this.knownUserService.knowUserType);
  }

  public checkAndOpenConfirmationDialog(): boolean {
    const isGuest = this.permissionService.hasDefined('guest:access');
    const blockConfirmation = !this.isModalClosed
    || this.knownUserService.isKnownSkipConfirm
    || this.isLoginWithoutPassword && this.knownUserService.getIsNotSetUnconfirmed();

    if (blockConfirmation || isGuest) {
      return false;
    }

    if (this.isUserKnownAndUnconfirmed() || this.knownUserService.isKnownSkipPassword) {
      this._setState({
        email: this.knownUserService.knowUserEmail,
        guid: this.knownUserService.knowUserGuid
      });
      this.openConfirmationDialog();
      this.confirmationShown = true;
      return true;
    }

    const finalStepType = localStorage.getItem(FINAL_STEP_KEY);

    if (finalStepType) {
      this._setState({ finalStepType });
      localStorage.removeItem(FINAL_STEP_KEY);

      this.confirmationTrack();
      this.sendConfirmAccountEvent();

      this.navigateToFinalStep();
      this.openConfirmationDialog();
      return true;
    }

    return false;
  }

  checkAndOpenPasswordDialog(): boolean {
    if (this.state.step !== AccountConfirmationStep.SetPassword || this.knownUserService.isKnownSkipPassword) {
      return;
    }

    if (this.isUserKnownAndNotSet()) {
      this.openSetPasswordDialog();
      return true;
    }

    const finalStepType = localStorage.getItem(FINAL_STEP_KEY);
    if (finalStepType) {
      this._setState({ finalStepType });
      localStorage.removeItem(FINAL_STEP_KEY);

      this.navigateToFinalStep();
      this.openSetPasswordDialog();
      return true;
    }

    return false;
  }

  checkAndOpenDialog(): boolean {
    if (this.isUserKnownAndUnconfirmed()) {
      this.openConfirmationDialog();
      return true;
    }

    if (this.isUserKnownAndNotSet()) {
      this.openSetPasswordDialog();
      return true;
    }
  }

  openConfirmationDialog(): void {
    if (!this.state.accountConfirmationOpen) {
      if (this.state.codeTimer === 0 && this.state.step === AccountConfirmationStep.EmailConfirmation && !this.awaitingFinalization) {
        const forceResend = !this.sessionStorageService.getItem<boolean>(OTP_SENT_KEY);
        void this.sendPascode(forceResend);
      }
      this._setState({ accountConfirmationOpen: true });
    }
  }

  openSetPasswordDialog(): void {
    if (!this.state.accountConfirmationOpen) {
      this._setState({ accountConfirmationOpen: true });
    }
  }

  closeConfirmationDialog(clearState = false, dismiss = false, stepToClose: AccountConfirmationStep = AccountConfirmationStep.EmailConfirmation, isMregEntryMode = false, outsideDismiss = false): void {
    this.setConfirmationWrapperState('void');
    setTimeout(() => {
      if (this.state.accountConfirmationOpen) {
        if (clearState || isMregEntryMode) {
          this._setState({
            step: stepToClose,
            accountConfirmationOpen: false,
            email: this.knownUserService.knowUserEmail,
            passcode: '',
            codeTimer: 0,
            emailEditable: false,
            errorMessage: '',
          });
          this.stopTimer();
        } else {
          this._setState({ accountConfirmationOpen: false });
        }
        if (dismiss) {
          this.trackEvent(AnalitycEvent.SignUpDropdownDismissal);
        }
      }
    }, TRANSITION_TIME);

    if (outsideDismiss) {
      this.allowProfilePrompts = [];
    }

    if (this.state.step === AccountConfirmationStep.ProfilePromptPhone || this.state.step === AccountConfirmationStep.ProfilePromptZip) {
      this.profilePromptsQueue.shift();
    }
  }

  navigateToChangeEmailStep(): void {
    this.setConfirmationWrapperState('void');
    setTimeout(() => {
      this._setState({
        step: AccountConfirmationStep.EmailChange,
        emailEditable: true
      });
    }, TRANSITION_TIME);
    this.stopTimer();
  }

  navigateToEmailConfirmation(): void {
    this.setConfirmationWrapperState('void');
    setTimeout(() => {
      this._setState({ step: AccountConfirmationStep.EmailConfirmation, emailEditable: false });
    }, TRANSITION_TIME);
  }

  navigateToFinalStep(): void {
    this.setConfirmationWrapperState('void');
    this.awaitingFinalization = true;

    setTimeout(() => {
      this._setState({ step: AccountConfirmationStep.Final });
      this.awaitingFinalization = false;
    }, TRANSITION_TIME);
  }

  navigateToFinalStepAfterNextReload(stepType: string): void {
    localStorage.setItem(FINAL_STEP_KEY, stepType);
  }

  setEmail(value: string): void {
    this._setState({
      email: value,
    });
  }

  setPasscode(value: string): void {
    this._setState({
      passcode: value,
    });
  }

  setPassword(value: string): void {
    this._setState({
      password: value,
    });
  }

  setZip(value: string): void {
    this._setState({ zip: value });
  }

  setPhone(value: string): void {
    this._setState({ phone: value });
  }

  async sendPascode(forceResend = false): Promise<void> {
    this._setState({ errorMessage: '' });
    try {
      const data: OtpData = {
        userGuid: this.state.guid,
        type: OtpTypes.email,
        contact: this.state.email,
        activateAccount: true,
        forceResend,
        reason: OtpReasonTypes.authConfirm
      };

      const otpCode = await this.oneTimePasswordService.createOtpCode(data);
      this.knownUserService.setKnownUserEmail(this.state.email);
      this.sessionStorageService.setItem(OTP_SENT_KEY, true);
      this.startTimer(otpCode);
    } catch (error) {
      this._setState({ errorMessage: this.oneTimePasswordService.getErrorMessage(error) });
    }
  }

  public async confirmEmail(): Promise<void> {
    try {
      this._setState({ errorMessage: '' });
      const rememberMe = this.knownUserService.knownRememberMe;
      const user = await this.oneTimePasswordService.authenticateUserByLoginAndOTP(this.state.guid, this.state.passcode, this.state.email, rememberMe);
      this.knownUserService.clearAll();
      this.knownUserService.clearSkipPassword();
      this.knownUserService.clearRememberMe();

      if (user) {
        this.setUserAccess(user);
      }

      localStorage.setItem(TRACK_OTP_AUTH, 'true');
      this.navigateToFinalStepAfterNextReload(FinalStepTypes.confirmEmail);
      this.sessionStorageService.removeItem(OTP_SENT_KEY);
      window.location.reload();
    } catch (error) {
      this._setState({ errorMessage: this.oneTimePasswordService.getErrorMessage(error) });
      this.trackEvent(AnalitycEvent.SignUpDropdownFailure);
    }
  }

  startTimer({ date }: OtpCode = {}): void {
    if (this._interval) {
      return;
    }

    const codeTimeout = this.oneTimePasswordService.getOtpCodeTimeout(date);
    this._setState({
      codeTimer: codeTimeout
    });

    let timer = codeTimeout;
    const interval = setInterval(() => {
      if (timer <= 0) {
        clearInterval(interval);
        this._interval = null;
      } else {
        timer--;
      }

      this._setState({
        codeTimer: timer
      });
    }, 1000);
    this._interval = interval;
  }

  stopTimer(): void {
    clearInterval(this._interval);
    this._interval = null;
    this._setState({
      codeTimer: 0
    });
  }

  onModalOpen(modal: NgbModalRef): void {
    if (!modal) {
      return;
    }

    this.isModalClosed = false;
    this.closeConfirmationDialog();

    modal.result
      .then(() => this.onModalClose())
      .catch(() => this.onModalClose());
  }

  trackEvent(event: AnalitycEvent): void {
    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('auth', {
        action: event
      }),
      new AnalyticsGAEventModel(event, {
        category: event
      })
    ]);
  }

  public openFinalConfirmationModal(): void {
    this._setState({ step: AccountConfirmationStep.Final, accountConfirmationOpen: true });
  }

  public openProfilePromptZipFinalModal(): void {
    this._setState({
      step: AccountConfirmationStep.Final,
      finalStepType: FinalStepTypes.setZip,
      accountConfirmationOpen: true
    });

    this.allowProfilePrompts = this.allowProfilePrompts.filter(el => el !== AccountConfirmationStep.ProfilePromptZip);
  }

  public openPaylogixProfileModal(source: string): void {
    if (!this.paylogixService.isPaylogixPromptAvailable()) {
      return;
    }

    this.analyticsService.eventsTrack([
      new AnalyticsGAEventModel('prompt-display', {
        category: 'paylogix',
        label: source
      })
    ]);
    this._setState({ step: AccountConfirmationStep.PaylogixProfile, accountConfirmationOpen: true });
  }

  public checkAndOpenProfilePromptModal(): void {
    // prevent opening the modal if the queue is finished
    if (!this.profilePromptsQueue.length && this.allowProfilePrompts.length) {
      return;
    }

    const confirmationToggle$ = this._state$.pipe(
      take(1),
      map((state) => state.accountConfirmationOpen),
      filter(isOpened => !isOpened),
      delay(400)
    );
    const allowProfilePrompts$ = this.profileService.getAllowProfilePrompts$()
      .pipe(
        take(1),
        map<AccountConfirmationStep[], AccountConfirmationStep[]>(shuffle)
      );

    confirmationToggle$.pipe(
      switchMap(() => allowProfilePrompts$)
    ).subscribe((allowProfilePrompts) => {
      if (!this.allowProfilePrompts.length) {
        this.allowProfilePrompts = [...allowProfilePrompts];
      }

      const isProfilePromptAllowed = this.allowProfilePrompts.length
        && this.authService.hasAccessToken()
        && !this.profileService.isGuestOrKnownUser()
        && this.isModalClosed
        && !this.modalService.hasOpenModal();

      if (!isProfilePromptAllowed) {
        return;
      }

      if (!this.profilePromptsQueue.length) {
        this.profilePromptsQueue =  [...allowProfilePrompts];
      }

      this.openProfilePromptModal(this.profilePromptsQueue);
    });
  }

  public openProfilePromptModal(prompts: AccountConfirmationStep[]): void {
    const step = first(prompts);
    if (step) {
      this._setState({ step, accountConfirmationOpen: true });
    }
  }

  public async showAdditionalNotifications(forceOpen?: boolean): Promise<void> {
    const isAllowedToDisplay = this.notificationQueue
      && this.notificationQueue.length > 0
      && this.isModalClosed;

    if (isAllowedToDisplay) {
      await this.displayQueue();
    }

    this.isMregHandled = this.ableToShowDropdown();

    if (!this.confirmationShown && this.isMregHandled || forceOpen) {
      this.checkAndOpenConfirmationDialog();
    }

  }

  private async displayQueue(): Promise<void> {
    for (const item of this.notificationQueue) {
      const notificationContent = {
        title: item.title,
        content: item.content,
        buttonTitle: item.buttonTitle,
        link_txt: item.link_txt,
        link_url: item.link_url
      };

      this.promptContentSource.next(notificationContent);

      this.openFinalConfirmationModal();
      await this.accountConfirmationOpen$.pipe(takeWhile(Boolean)).toPromise();
    }

    this.notificationQueue = [];
  }

  private onModalClose(): void {
    this.isModalClosed = true;
    setTimeout(() => {
      this.checkAndOpenPasswordDialog();
      void this.showAdditionalNotifications();
      this.checkAndOpenProfilePromptModal();
    }, 0);
  }

  private _select<T>(selector: (state: AccountConfirmationState) => T): Observable<T> {
    return this._state$.pipe(map(selector)).pipe(distinctUntilChanged());
  }

  private _setState(state: Partial<AccountConfirmationState>): void {
    this._state$.next({ ...this.state, ...state });
  }

  private confirmationTrack(): void {
    if (localStorage.getItem(TRACK_OTP_AUTH)) {
      localStorage.removeItem(TRACK_OTP_AUTH);
      this.trackEvent(AnalitycEvent.SignUpConfirmationSuccessOTP);
    } else {
      // The final step was set via DLK query param 'final_step'
      this.trackEvent(AnalitycEvent.SignUpConfirmationSuccessDLK);
    }
  }

  private setUserAccess(user: { scopes: string[]; access_token: string; short_token: string }): void {
    if (user.scopes) {
      this.permissionService.define(user.scopes);
    }
    if (user.access_token) {
      localStorage.setItem(ACCESS_TOKEN_KEY, user.access_token);
    }
    if (user.short_token) {
      localStorage.setItem(SHORT_TOKEN_KEY, user.short_token);
    }
  }

  private ableToShowDropdown(): boolean {
    return this.isMregHandled === false
      ? !!(localStorage.getItem(MREG_ENTRY_DATE) || localStorage.getItem(MREG_ENTRY_DATE))
      : this.isMregHandled;
  }

  private sendConfirmAccountEvent(): void {
    this.profileService.getUserEncryptedEmail(true)
      .subscribe(encryptedLogin => {
        const userObservable: Observable<{ guid: string; enroll_type: string }> = this.profileService.profileData$.pipe(
          filter(data => !!data.guid),
          map(user => ({ guid: user.guid, enroll_type: user?.enroll_type || ACCOUNT_ENROLL_TYPES.Traditional }))
        );

        userObservable.pipe(take(1)).subscribe(user => {
          this.adobeAnalyticsEventsService.emitConfirmEmailEvent(user.enroll_type, user.guid, encryptedLogin);
        });
      });
  }
}
