import { Component, OnInit } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { ConfigService } from '@app/shared/services/config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-site-error',
  templateUrl: './no-site-error.component.html',
  styleUrls: ['./no-site-error.component.less']
})
export class NoSiteErrorComponent implements OnInit {

  text = 'We\'re sorry! That marketplace doesn\'t exist.<br/>Please make sure you typed the address correctly.';
  url = 'https://bp.beneplace.com/solutions/savings-platforms/';
  noSite = false;

  constructor(
    private router: Router,
    private configService: ConfigService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.isMarketplaceExist();
  }

  async isMarketplaceExist(): Promise<void> {
    let data = this.configService.getConfig();

    if (isEmpty(data)) {
      try {
        data = await this.configService.getMarketplaceInfo();
      } catch (error) {
        console.error('This marketplace can\'t be processed by application: ', error);
        this.noSite = true;
      }
    }
    if (!isEmpty(data) && data.subdomain) {
      void this.router.navigate(['/home']);
    } else {
      this.noSite = true;
    }
  }
}
