import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { firstValueFrom, fromEvent, Subscription } from 'rxjs';
import { UrlHelperService, WINDOW } from 'g3-common-ui';
import { EmployerPortalService } from '@app/employer-portal/services/employer-portal.service';
import { ConfigService } from '@app/shared/services/config.service';
import { NavigationService } from '@app/shared/services/navigation.service';
import { Breakpoints } from '@shared/enums/breakpoints.enum';
import { NavDynamicItem } from '../interfaces/nav-dynamic-section.interface';

interface NavListItem {
  active: boolean;
  primaryNav: NavDynamicItem;
  items: NavDynamicItem[];
}
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})

export class FooterComponent implements OnInit, OnDestroy {
  @Input() public country: string;
  @Input() public siteTag: string;
  @Input() public hostName: string;
  @Input() public apiHost: string;
  @Input() public cdn: string;
  @Input() public canShowFooter: boolean;
  @ViewChild('siteseal', { static: true }) public sitesealElement: ElementRef;

  public copyrightsLinks: NavDynamicItem[] = [];
  public brandsRights: NavDynamicItem;
  public disclaimer: NavDynamicItem;
  public navItems: NavListItem[] = [];
  public isWorkingAdvantage = false;
  public loaded = false;
  public TAB_INDEX = 99000;
  public SEAL_CONTAINER_ID = 'siteseal';

  private MAX_COPYRIGHT_LINKS = 6;
  private MAX_COPYRIGHT_TEXTS = 2;
  private MAX_NAV_COLUMNS = 3;
  private isPrevLayoutDesktop = true;
  private resizeSubscription: Subscription;

  constructor(
    private urlHelper: UrlHelperService,
    private navigationService: NavigationService,
    private configService: ConfigService,
    private employerPortalService: EmployerPortalService,
    @Inject(WINDOW) private window: WINDOW,
  ) {}

  public get wrapperOpacity(): string {
    return this.loaded ? '1' : '0';
  }

  public async ngOnInit(): Promise<void> {
    this.isWorkingAdvantage = this.urlHelper.isWorkingAdvantage();
    this.isPrevLayoutDesktop = this.window.innerWidth >= Breakpoints.Medium;

    this.initResizeListener();

    if (this.isWorkingAdvantage) {
      this.addGodaddySeal();
    }

    await this.initNavigationItems();
    this.loaded = true;
  }

  public ngOnDestroy(): void {
    this.resizeSubscription?.unsubscribe();
  }

  public onResize(innerWidth: number): void {
    const isDesktop = innerWidth >= Breakpoints.Medium;
    const isLayoutChange = this.isPrevLayoutDesktop !== isDesktop;
    this.isPrevLayoutDesktop = isDesktop;

    if (!isLayoutChange) {
      return;
    }

    this.navItems = this.navItems.map((navItem) => ({
      ...navItem,
      active: isDesktop
    }));
  }

  public navItemClickHandler(item: NavListItem): void {
    if (this.window.innerWidth >= Breakpoints.Medium) {
      return;
    }
    this.navItems = this.navItems.map((navItem) => {
      if (navItem.primaryNav.id === item.primaryNav.id) {
        return {
          ...navItem,
          active: !navItem.active
        };
      }
      return navItem;
    });
  }

  public scrollToTop(): void {
    this.window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  private initResizeListener(): void {
    this.resizeSubscription = fromEvent(window, 'resize')
      .subscribe(() => {
        this.onResize(this.window.innerWidth);
      });
  }

  private addGodaddySeal(): void {
    const sealScript = document.createElement('script');

    sealScript.setAttribute('async', '');
    sealScript.setAttribute('type', 'text/javascript');
    sealScript.setAttribute('src', 'https://seal.godaddy.com/getSeal?sealID=bUmeg61DHw9ieUI4pENDh8aAXuvuyyLp47aPMJfzXKE4wIaC0rflOqvSA7VT');

    document.body.appendChild(sealScript);
    sealScript.onload = (): void => {
      const element = this.sitesealElement.nativeElement;
      const clonedElement = element.cloneNode(true);
      const container = document.getElementById('siteseal-mobile');
      if (container) {
        container.appendChild(clonedElement);
      }
      this.loaded = true;
    };
  }

  private async initNavigationItems(): Promise<void> {
    const subdomain = this.configService.getOption<string>('subdomain', '');
    const copyrights: NavDynamicItem[] = [];
    const primaryNavItems: NavDynamicItem[] = [];
    const secondaryNavItems: NavDynamicItem[] = [];
    const navMap = new Map<string, NavListItem>();

    const { items } = await firstValueFrom(this.navigationService.getNavigationItems(subdomain, 'footer', this.country, false, 'auth'));
    items.sort((a, b) => b.priority - a.priority);

    items.forEach(item => {
      if (item.id.startsWith('special^')) {
        if (item.id.startsWith('special^copyrights')) {
          copyrights.push(item);
        }
      } else if (item.parent === '') {
        primaryNavItems.push(item);
      } else {
        secondaryNavItems.push(item);
      }
    });

    primaryNavItems.forEach(item => {
      navMap.set(item.id, { primaryNav: item, items: [], active: this.isPrevLayoutDesktop });
    });

    secondaryNavItems.forEach(item => {
      const nav = navMap.get(item.parent);
      if (nav) {
        nav.items.push({
          ...item,
          url: this.urlHelper.isExternalUrl(item.url) ? item.url : `${this.hostName}${item.url}`
        });
      }
    });

    const navArray = Array.from(navMap.values());

    const employerPortalAccess = (await this.employerPortalService.checkEmployerPortalAccess());
    if (employerPortalAccess.has_access_to_ep){
      navArray.push({
        active: this.isPrevLayoutDesktop,
        primaryNav: {
          id: 'resources',
          name: 'Resources',
          url: '/ep',
          priority: 0,
          parent: '',
          icon_bw: '',
          count_search_results: 0,
          guid: '',
          icon_color: '',
          is_ebg: false
        },
        items: [{
          id: '',
          name: 'Employer Portal',
          parent: 'resources',
          priority: 0,
          url: `${employerPortalAccess.ep_link}`,
          icon_bw: '',
          count_search_results: 0,
          guid: '',
          icon_color: '',
          is_ebg: false
        }]
      });
    }

    this.navItems = navArray.filter(nav => nav?.items?.length).slice(0, this.MAX_NAV_COLUMNS);
    this.copyrightsLinks = copyrights
      .filter((item) => item.url)
      .slice(0, this.MAX_COPYRIGHT_LINKS)
      .map((item) => ({...item, url: this.urlHelper.isExternalUrl(item.url) ? item.url : `${this.hostName}${item.url}`}));
    const [brandsRights, disclaimer] = copyrights.filter((item) => !item.url).slice(0, this.MAX_COPYRIGHT_TEXTS);
    this.brandsRights = brandsRights;
    this.disclaimer = disclaimer;
  }
}
