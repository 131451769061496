import { KeyValue } from '@angular/common';
import { AccessSettingOption } from '../interfaces/search-offer-item.interface';
import { OfferExternalData, OfferExternalSources } from './offer.model';
import { PaylogixSubscription } from '@shared/models/offer.model';
import { SUCHelper } from '../helpers/suc.helper';

/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
export enum AdSource {
  BSS = 'bss',
  Natural = 'natural',
  TDD = 'tdd'
}

export class Ad {
  public id: string;
  public guid?: string;
  public clickUrl?: string;
  public adUrl?: string;
  public imageUrl: string;
  public dbImageUrl?: string;
  public dbImageUrlSmall?: string;
  public imageSource?: string;
  public title?: string;
  public titleShort?: string;
  public vendorImage?: string;
  public company?: string;
  public impressionUrl?: string;
  public adTypeDetected?: string;
  public isGeneratedAd?: boolean;
  public is_saved?: boolean;
  public extraStatus?: string;
  public startDate?: Date;
  public endDate?: Date;
  public template?: '';
  public isHtmlTemplate?: boolean;
  public views?: number;
  public class: string;

  public descriptionDetails?: string;
  public code?: string;
  public instruction_text?: string;
  public code_instructions?: string;
  public destinationUrl?: string;
  public destination_url_new_tab: boolean;
  public destination_url_type: string;
  public hasDestinationUrl?: boolean;
  public isAllowedDestinationUrl?: boolean;
  public ctaButtonTitle?: string;
  public urlType?: string;
  public phone?: string;
  public phone_note?: string;
  public phone_action?: string;
  public campaign?: string;
  public imageSquare?: string;
  public priority: number;
  public source?: AdSource;
  public content?: { key: string; value: string }[];
  public contentGuid?: string;
  public currentPrice?: number;
  public originalPrice?: number;
  public offerGroup?: boolean;
  public isPinned?: boolean;
  public isEbg: boolean;
  public tagText: string;
  public responseId?: string;
  public catCodeTagIds?: number[];

  public productName: string;
  public productSubHeading: string;
  public brandOverride: string;
  public brandTagDisplay?: string;
  public customProductSubHeadings: string;
  public headerShort: string;
  public vendorBrand: {
    title: string;
  };
  public descriptionDisclaimer: string;
  public append_url_params?: string;
  public isAuthRequired: boolean;
  public access_setting: AccessSettingOption;
  public source_based_params: KeyValue<string, string>[];
  public external_source: string;
  public external_data?: OfferExternalData;
  public paylogixSubscription: PaylogixSubscription;
  public paylogix_enrolled_header_short: string;
  public append_internal_url_params?: string;
  public append_g3_url_params?: string;
  public isPaylogixAd = false;
  public paylogixEnrolledHeaderShort: string;
  public isNewTab?: boolean;

  public is_widget_iframe?: boolean;
  public is_widget_disclaimer?: boolean;
  public widget_code?: string;
  public widget_disclaimer?: string;
  public shopping_category?: string;
  public brand_name?: string;

  public constructor(data: any = {}) {
    Object.assign(this, data);
    this.isPaylogixAd = this.external_source === OfferExternalSources.Paylogix;
  }

  public hasCouponCode(): boolean {
    return !!this.code;
  }

  public hasPhone(): boolean {
    return !!this.phone;
  }

  public getContent(): Record<string, string> {
    const map: Record<string, string> = this.content?.reduce((acc, value) => {
      acc[value.key] = value.value;
      return acc;
    }, {});
    return map ?? {};
  }

  public getCtaButtonType(): string {
    const isDestinationCode = this.isExternalUrlWithSucToken();
    const isModal = !isDestinationCode && (this.hasCouponCode() || this.hasPhone());

    if (isModal && this.external_source !== 'paylogix') {
      return 'modal';
    }
    if (this.hasDestinationUrl) {
      return 'external';
    }
    return '';
  }

  public getCtaButtonTitle(isProductCard = false, marketplaceHasPaylogixId = false, userHasPaylogixId = false, isEnrollmentAvailable = false, isMobile = false): string {
    const isSetPaylogix = this.external_source === 'paylogix' && marketplaceHasPaylogixId && userHasPaylogixId;
    if (isSetPaylogix) {
      if (!isEnrollmentAvailable) {
        return 'LEARN MORE';
      }

      if (this.paylogixSubscription) {
        return 'MANAGE';
      } else {
        return isMobile ? 'ENROLL' : 'ENROLL NOW';
      }
    }

    const defaultTitle = isProductCard ? 'SHOP PRODUCT' : 'GET OFFER';

    if (this.isGetButtonExternal() || this.ctaButtonTitle) {
      return this.ctaButtonTitle || defaultTitle;
    }

    if (this.isExternalUrlWithSucToken()) {
      return defaultTitle;
    }

    return this.code ? 'GET CODE' : defaultTitle;
  }

  public isGetButtonExternal(): boolean {
    if (!this.code && !this.phone && this.hasDestinationUrl) {
      return true;
    }
    return false;
  }

  public isExternalUrlWithSucToken(): boolean {
    return this.hasCouponCode() && SUCHelper.isExternalUrlWithSucToken(this.destinationUrl, this.code);
  }
}
