/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  Inject,
  Injectable,
  Injector
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { WINDOW } from 'g3-common-ui';
import { BehaviorSubject, Observable } from 'rxjs';
import get from 'lodash-es/get';
import { HttpClient } from '@angular/common/http';
import { Angulartics2 } from 'angulartics2';

import {
  AdobeHomepageDataService,
  AdobeSearchPageDataService,
  AdobePoliciesDataService,
  AdobeUserProfileDataService
} from './units';

import { environment } from '@environments/environment';
import { ConfigService } from '../config.service';
import { AdobeNearMeMapsPageDataService } from './units/adobe-nearmepage-data.service';
import { AdobeOfferDetailspageDataService } from './units/adobe-offerdetailspage-data.service';
import { AdobeBrandspageDataService } from './units/adobe-brandspage-data.service';
import { AdobeCategoriespageDataService } from './units/adobe-categoriespage-data.service';
import { AdobeAnalyticsEventsService } from './units/adobe-analytics-events.service';
import { KnownUserService } from '@shared/services/known-user.service';

enum PagesForAnalytics {
  Home = '/home',
  Offers = '/offers',
  NearMeMaps = '/listing',
  Policies = '/common',
  Profile = '/my-account',
  OffersDetails = '/offers/',
  Brands = '/browse-offers/brands',
  Categories = '/browse-offers/categories'
}

const SkipPageTrackOnQueryParams = {
  'prompt': 'pylgx'
} as const;

@Injectable({ providedIn: 'root' })
export class AdobeAnalyticsLaunchService {

  public isFirstPageView = true;

  public adobePageTracked: Observable<boolean>;
  private adobePageTracked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private knownUserService: KnownUserService,
    private injector: Injector,
    private configService: ConfigService,
    @Inject(WINDOW) private window: WINDOW,
    private readonly adobeAnalyticsEventsService: AdobeAnalyticsEventsService
  ) {
    this.adobePageTracked = this.adobePageTracked$.asObservable();
  }

  public startTracking(): void {
    this.injector.get(Angulartics2).pageTrack.subscribe((x) => {
      const queryParams = this.injector.get(ActivatedRoute).snapshot?.queryParams;
      const currentNavId = (this.injector.get(Location).getState() as { navigationId: number })?.navigationId;
      const isSkipParam = Object.entries(SkipPageTrackOnQueryParams).some(([key, value]) => queryParams?.[key] != null && queryParams[key] === value);
      if (currentNavId > 1 && isSkipParam) {
        return;
      }

      this.pageTrack(x.path);
    });
  }

  private pageTrack(path: string): void {
    switch (path) {
      case PagesForAnalytics.Home:
        this.injector.get(AdobeHomepageDataService).setHomepageData()
          .then(() => this.adobePageTrack())
          .catch(err => {
            console.error(`Page track cannot process input data for ${path}`, err);
            this.sendAdobeDebugData(err.message);
            this.adobePageTracked$.next(true);
          });
        break;

      case PagesForAnalytics.Offers:
        this.injector.get(AdobeSearchPageDataService).setSearchPageData()
          .then(() => this.adobePageTrack())
          .catch(err => {
            console.error(`Page track cannot process input data for ${path}`, err);
            this.adobePageTracked$.next(true);
          });
        break;

      case PagesForAnalytics.Brands:
        this.injector.get(AdobeBrandspageDataService).setBrandsPageData()
          .then(() => this.adobePageTrack())
          .catch(err => {
            console.error(`Page track cannot process input data for ${path}`, err);
            this.adobePageTracked$.next(true);
          });
        break;

      case PagesForAnalytics.NearMeMaps:
        this.injector.get(AdobeNearMeMapsPageDataService).setNearMeMapsPageData()
          .then(() => this.adobePageTrack())
          .catch(err => {
            console.error(`Page track cannot process input data for ${path}`, err);
            this.adobePageTracked$.next(true);
          });
        break;

      case PagesForAnalytics.Categories:
        this.injector.get(AdobeCategoriespageDataService).setCategoriesPageData()
          .then(() => this.adobePageTrack())
          .catch(err => {
            console.error(`Page track cannot process input data for ${path}`, err);
            this.adobePageTracked$.next(true);
          });
        break;

      default: break;
    }
    if (path.includes(PagesForAnalytics.Policies)) {
      this.injector.get(AdobePoliciesDataService).setPoliciesData(path.replace(PagesForAnalytics.Policies + '/', ''))
        .then(() => this.adobePageTrack())
        .catch(err => {
          console.error(`Page track cannot process input data for ${path}`, err);
          this.adobePageTracked$.next(true);
        });
    }

    if (path.includes(PagesForAnalytics.OffersDetails)) {
      this.injector.get(AdobeOfferDetailspageDataService).setDetailsPageData(path)
        .then((status) => {
          if (status === 'active') {
            this.adobePageTrack();
          } else {
            this.adobePageTracked$.next(true);
          }
        }).catch(err => {
          console.error(`Page track cannot process input data for ${path}`, err);
          this.adobePageTracked$.next(true);
        });
    }

    if (path.includes(PagesForAnalytics.Profile)) {
      this.injector.get(AdobeUserProfileDataService).setMyProfileData(path)
        .then(() => this.adobePageTrack())
        .catch(err => {
          console.error(`Page track cannot process input data for ${path}`, err);
          this.adobePageTracked$.next(true);
        });
    }
  }

  private isPageTracking(): boolean {
    return typeof (this.window as any)._satellite !== 'undefined' && (this.window as any)._satellite;
  }

  private runAdobeEvents(): void {
    this.adobeAnalyticsEventsService.emitPageviewEvent();

    if (this.isFirstPageView) {
      (this.window as any)._satellite.pageBottom();
      setTimeout(() => {
        if (!get(this.window ,'ebg.analytics.user.enrolldate')) {
          this.sendAdobeDebugData('window');
        } else if (!this.window?._satellite?.getVar('user_enrolldate')) {
          this.sendAdobeDebugData('_satellite');
        }
      }, 300);
      this.isFirstPageView = false;
    }
  }

  private adobePageTrack(): void {
    setTimeout(() => {
      if (this.configService.sendViaAdobeSatelite() && this.isPageTracking()) {
        console.info('Adobe Analytics Data: ', JSON.stringify((this.window as any).ebg.analytics || '', null, 2));
        this.runAdobeEvents();
      }

      this.adobePageTracked$.next(true);
    }, 300);
  }

  private sendAdobeDebugData(source: string): void {
    const userGuid = get(this.window, 'ebg.analytics.user.guid');
    const knownGuid = this.knownUserService.knowUserGuid;
    const knownChildGuid = this.knownUserService.knowUserChildGuid;

    this.http.post(`${environment.apiUrl}/api/adobe-debug`, {
      message: `No first_access_date. source: ${source}, guid: ${userGuid}, known guid: ${knownGuid}, known child guid: ${knownChildGuid}`
    }).subscribe();
  }
}
