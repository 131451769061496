import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyOffersService } from '@app/my-profile/services/my-offers.service';
import { NpsSurveyModule } from '@app/nps-survey/nps-survey.module';
import { SharedModule } from '@app/shared/shared.module';
import { AuthFooterComponent } from '@core/auth/auth-footer/auth-footer.component';
import { ProfileDeletedComponent } from '@core/auth/profile-deleted/profile-deleted.component';
import { UpgradeVersionComponent } from '@core/auth/upgrade-version/upgrade-version.component';
import { NgbCollapseModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ZonesModule } from '@zones/zones.module';
import { G3CommonUiModule } from 'g3-common-ui';
import { NoSiteErrorComponent } from './auth/no-site-error/no-site-error.component';
import { SignOutComponent } from './auth/sign-out/sign-out.component';
import { FooterComponent } from './layouts/logged-in/footer/footer.component';
import { HeaderComponent } from './layouts/logged-in/header/header.component';
import { LoggedInLayoutComponent } from './layouts/logged-in/logged-in-layout.component';
import { SubNavigationMenuComponent } from './layouts/logged-in/header/shared/sub-navigation-menu/sub-navigation-menu.component';
import { SearchBarComponent } from './layouts/logged-in/header/shared/search-bar/search-bar.component';
import { SearchBarItemComponent } from './layouts/logged-in/header/shared/search-bar-item/search-bar-item.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    NgbCollapseModule,
    NgbPopoverModule,
    NgbModule,
    G3CommonUiModule,
    NpsSurveyModule,
    ZonesModule
  ],
  declarations: [
    // Layouts
    HeaderComponent,
    FooterComponent,
    LoggedInLayoutComponent,
    NoSiteErrorComponent,
    SignOutComponent,
    SearchBarComponent,
    SearchBarItemComponent,
    SubNavigationMenuComponent,
    ProfileDeletedComponent,
    UpgradeVersionComponent,
    AuthFooterComponent,
  ],
  exports: [
    LoggedInLayoutComponent,
  ],
  providers: [
    MyOffersService,
  ]
})
export class CoreModule { }
