import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import get from 'lodash-es/get';
import { ConfigApiResponse, ConfigService } from '@app/shared/services/config.service';
import { ProfileService } from '@app/shared/services/profile/profile.service';
import { PhoneValidator } from '@app/shared/validators/phone.validator';
import { DateOfBirthValidator } from '@app/shared/validators/date-of-birth.validator';
import { PaylogixService } from '@app/shared/services/paylogix/paylogix.service';
import { PAYLOGIX_PROFILE_KEYS } from './paylogix-profile-step.constant';
import { AnalyticsService } from '@shared/services/analytics.service';
import { AnalyticsGAEventModel } from '@shared/models/analytics.event.model';
import { QueryParamService } from 'g3-common-ui';
import dayjs from 'dayjs';

const PAYLOGIX_PROMTPT_CONTROLS = [
  {
    key: PAYLOGIX_PROFILE_KEYS.ssn,
    placeholder: 'Last 4 Digits of Social Security Number',
    type: 'password',
    label: 'Last 4 Digits of SSN',
    maxlength: 4,
    errors: [
      {
        type: 'required',
        text: 'Please enter last 4 digits of SSN'
      },
      {
        type: 'pattern',
        text: 'Last 4 should be 4 digits'
      }
    ]
  },
  {
    key: PAYLOGIX_PROFILE_KEYS.dob,
    placeholder: 'MM/DD/YYYY',
    type: 'text',
    label: 'Date of Birth',
    maxlength: 10,
    errors: [
      {
        type: 'required',
        text: 'Please enter date of birth'
      },
      {
        type: 'pattern',
        text: 'Date of birth should be a valid date'
      }
    ]
  },
  {
    key: PAYLOGIX_PROFILE_KEYS.employee_id,
    placeholder: 'Employee ID',
    type: 'text',
    label: 'Employee ID',
    errors: [
      {
        type: 'required',
        text: 'Please enter employee ID'
      }
    ]
  },
  {
    key: PAYLOGIX_PROFILE_KEYS.phone,
    placeholder: 'Phone Number (XXXXXXXXXX)',
    type: 'text',
    label: 'Phone Number',
    errors: [
      {
        type: 'required',
        text: 'Please enter phone number'
      },
      {
        type: 'pattern',
        text: 'Phone should contain 10 digits'
      }
    ]
  },
  {
    key: PAYLOGIX_PROFILE_KEYS.zip,
    placeholder: 'Zip Code',
    type: 'text',
    label: 'Zip Code',
    maxlength: 5,
    errors: [
      {
        type: 'required',
        text: 'Please enter zip code'
      },
      {
        type: 'pattern',
        text: 'Zip should contain 5 digits'
      }
    ]
  },
  {
    key: PAYLOGIX_PROFILE_KEYS.last_name,
    placeholder: 'Last Name',
    type: 'text',
    label: 'Last Name',
    errors: [
      {
        type: 'required',
        text: 'Please provide a valid name'
      },
      {
        type: 'pattern',
        text: 'Please provide a valid name'
      }
    ]
  }
];

@Component({
  selector: 'app-paylogix-profile-step',
  templateUrl: './paylogix-profile-step.component.html',
  styleUrls: ['./paylogix-profile-step.component.less']
})
export class PaylogixProfileStepComponent implements OnInit {

  public marketplaceInfo: ConfigApiResponse;
  public verificationFields: string[] = [];
  public isLoading = false;
  public controls = [];
  public paylogixProfileForm = new UntypedFormGroup({
    [PAYLOGIX_PROFILE_KEYS.ssn]: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
    [PAYLOGIX_PROFILE_KEYS.dob]: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('^(0?[1-9]|1[012])/(0?[1-9]|[12][0-9]|3[01])/[0-9]{4}$'),
      DateOfBirthValidator
    ]),
    [PAYLOGIX_PROFILE_KEYS.employee_id]: new UntypedFormControl('', [Validators.required]),
    [PAYLOGIX_PROFILE_KEYS.phone]: new UntypedFormControl('', [Validators.required, PhoneValidator]),
    [PAYLOGIX_PROFILE_KEYS.zip]: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]{5}')]),
    [PAYLOGIX_PROFILE_KEYS.last_name]: new UntypedFormControl('', [Validators.required, Validators.pattern('.*[^ ].*')]),
  });
  public headerText = 'Securely Link Your Voluntary Benefits';
  public subheaderText = '';

  public constructor(
    private readonly configService: ConfigService,
    private readonly profileService: ProfileService,
    private readonly paylogixService: PaylogixService,
    private readonly analyticsService: AnalyticsService,
    private readonly queryParamService: QueryParamService
  ) { }

  public ngOnInit(): void {
    this.marketplaceInfo = this.configService.getConfig();
    this.subheaderText = `Please provide the following information to connect your account to your
    employer’s benefits center. By completing this form, we will be able to provide you with voluntary benefits
    available to ${this.marketplaceInfo.name}.`;
    this.initializeFormControls();
  }

  public onConfirmClick(event: Event): void {
    if (!event) {
      return;
    }

    event.stopPropagation();

    if (this.paylogixProfileForm.valid) {
      const paylogixData = this.getFormData();
      const body: Record<string, string> = {
        paylogix_participant_id: paylogixData.participant_id,
        paylogix_employee_id: paylogixData.employee_id,
        paylogix_phone: paylogixData.phone,
        paylogix_last_name: paylogixData.last_name,
        ssn: paylogixData.ssn,
        zip_code: paylogixData.zip
      };
      if (paylogixData.dob) {
        body.birthday = dayjs(paylogixData.dob).format('YYYY-MM-DD');
      }
      this.isLoading = true;
      this.profileService.updateProfile(body)
        .then(async () => this.paylogixService.matchUsers([this.profileService.profile.getValue().guid]))
        .then(() => {
          this.analyticsService.eventsTrack([
            new AnalyticsGAEventModel('prompt-completed', {
              category: 'paylogix'
            })
          ]);
          this.queryParamService.removeParamFromUrl('prompt');
          this.queryParamService.updateUrlQueryParams();
          window.location.reload();
          this.isLoading = false;
        })
        .catch((err) => {
          this.analyticsService.eventsTrack([
            new AnalyticsGAEventModel('prompt-failure', {
              category: 'paylogix'
            })
          ]);
          this.isLoading = false;
          console.error('Cannot update profile', err);
          this.headerText = 'We did not find a match';
          this.subheaderText = 'Please check the information you provided and try again. if you continue to have issues, contact support.';
        });
    }
  }

  /**
   * Initializes form controls that are needed based on marketplace and user information
   */
  private initializeFormControls(): void {
    this.verificationFields =  get(this, 'marketplaceInfo.vendor_settings.paylogix_verification_fields', [])
      .filter((field: string) => PAYLOGIX_PROFILE_KEYS[field]);
    this.controls = this.verificationFields.map(verificationField => PAYLOGIX_PROMTPT_CONTROLS.find(control => control.key === verificationField));

    const controlsToDelete = Object.keys(PAYLOGIX_PROFILE_KEYS).filter(el => !this.verificationFields.includes(el));
    if (controlsToDelete && controlsToDelete.length) {
      controlsToDelete.forEach(name => this.paylogixProfileForm.removeControl(name));
    }

    this.setFormDefaultValues();
    const dobControl = this.paylogixProfileForm.controls[PAYLOGIX_PROFILE_KEYS.dob];
    if (dobControl) {
      dobControl.valueChanges.subscribe((val: string) => {
        if (
          (val.length === 3 || val.length === 6) &&
          val.charAt(val.length - 2) !== '/' &&
          val.charAt(val.length - 1) !== '/' &&
          (val.match(/\//g) || []).length < 2
        ) {
          const newValue = val.substring(0, val.length - 1) + '/' + val.substring(val.length - 1);
          dobControl.setValue(newValue);
        }
      });
    }
  }

  private getFormData(): { [key: string]: string } {
    return this.verificationFields.reduce((acc, el) => {
      const fieldValue = this.getFieldValue(el);
      acc[el] = fieldValue.trim();
      return acc;
    }, {});
  }

  private getFieldValue(fieldName: string): string {
    if (fieldName === 'phone') {
      let phone: string = this.paylogixProfileForm.get(fieldName).value;
      phone = phone.replace(/\D/g, '');
      if (phone.charAt(0) === '1') {
        phone = phone.substring(1, phone.length - 1);
      }
      return phone;
    }
    return this.paylogixProfileForm.get(fieldName) ? this.paylogixProfileForm.get(fieldName).value : '';
  }

  private setFormDefaultValues(): void {
    const profile = this.profileService.getData();
    const userData = {
      participant_id: profile.vendorSettings.paylogixParticipantId,
      dob: profile.birthday && dayjs(profile.birthday).utc().format('MM/DD/YYYY'),
      zip: profile.zipCode,
      last_name: profile.lastName || ''
    };

    this.verificationFields.forEach(el => userData[el] && this.paylogixProfileForm.controls[el].setValue(userData[el]));
  }
}
