import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const DateOfBirthValidator: ValidatorFn = (control: AbstractControl): (ValidationErrors | null) => {
  const { value } = control;
  const date = dayjs(value, ['M/D/YYYY', 'MM/D/YYYY', 'MM/DD/YYYY', 'M/DD/YYYY'], true);
  const isValid = date.isValid() && date.isBefore(dayjs(Date.now()));

  return isValid ? null : { pattern: { value } };
};
