<div id="g3_footer"
     [style.opacity]="wrapperOpacity"
     [style.display]="canShowFooter ? 'block' : 'none'">
  <div class="g3_footer_top_line"></div>
  <footer class="g3_footer" [class.loading]="!loaded">
    <div class="g3_footer_navigation">
      <div class="g3_footer_nav_container">
        <div class="g3_footer_logo">
          <img id="beneplace_logo"
               class="g3_footer_logo_img"
               src="/assets/footer/beneplace.png">
          <img id="wa_logo"
               class="g3_footer_logo_img"
               src="/assets/footer/working_advantage.png">
        </div>
        <div class="g3_footer_navigation_links">
          <div *ngFor="let item of navItems" class="g3_footer_navigation_list" [class.active]="item.active">
            <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.294922 2.11508L4.87492 6.70508L0.294922 11.2951L1.70492 12.7051L7.70492 6.70508L1.70492 0.705078L0.294922 2.11508Z" fill="#757575"/>
            </svg>
            <h5 (click)="navItemClickHandler(item)" class="g3_footer_navigation_title">{{item.primaryNav.name}}</h5>
            <div class="g3_footer_navigation_list_items">
              <a *ngFor="let nav of item.items"
                 [href]="nav.url"
                 [target]="nav.name === 'Employer Portal' ? '_blank' : '_self'"
                 class="g3_footer_navigation_item"
                 [tabIndex]="TAB_INDEX">{{nav.name}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="g3_footer_payments_and_secure desktop">
        <div class="g3_footer_payments">
          <p class="g3_footer_title">flexible, secure payment options</p>
          <div class="g3_footer_payments_container">
            <img class="g3_footer_icon"
                 src="/assets/footer/visa.png">
            <img class="g3_footer_icon"
                 src="/assets/footer/mastercard.png">
            <img class="g3_footer_icon"
                 src="/assets/footer/american_express.png">
            <img class="g3_footer_icon"
                 src="/assets/footer/discover.png">
            <img class="g3_footer_icon"
                 src="/assets/footer/paypal.png">
          </div>
        </div>
        <div class="g3_footer_secure">
          <p class="g3_footer_title">guaranteed secure</p>
          <div class="g3_footer_secure_container">
            <img class="g3_footer_icon"
                 src="/assets/footer/chase.png">
            <span #siteseal class="g3_footer_icon" [id]="SEAL_CONTAINER_ID">
              <img *ngIf="!isWorkingAdvantage" src="/assets/footer/norton.png">
            </span>
            <img class="g3_footer_icon" src="/assets/footer/credit_safe.png">
          </div>
        </div>
        <div class="g3_footer_back_to_top">
          <button tabindex="99101" (click)="scrollToTop()" class="g3_footer_back_to_top_button">
            Back To Top
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.61295 0.160398C3.61279 0.160563 3.6126 0.160699 3.61243 0.160891L0.768684 3.01842C0.555649 3.2325 0.556442 3.57875 0.770543 3.79184C0.984618 4.0049 1.33087 4.00408 1.54393 3.79001L3.45318 1.87146L3.45318 13.4531C3.45318 13.7552 3.69802 14 4.00006 14C4.3021 14 4.54693 13.7552 4.54693 13.4531L4.54693 1.87149L6.45618 3.78998C6.66925 4.00406 7.0155 4.00488 7.22957 3.79181C7.4437 3.57872 7.44444 3.23244 7.23143 3.01839L4.38768 0.160863C4.38752 0.160699 4.38733 0.160563 4.38716 0.160371C4.17339 -0.053813 3.82602 -0.0531292 3.61295 0.160398Z"
                fill="#757575"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="g3_footer_info">
      <div *ngIf="copyrightsLinks.length > 0" class="g3_footer_content">
        <p *ngFor="let copyright of copyrightsLinks" class="g3_footer_content_info">
          <a [href]="copyright.url" class="g3_footer_content_info_link" [tabIndex]="TAB_INDEX">
            {{copyright.name}}
          </a>
        </p>
      </div>
      <div class="g3_footer_payments_and_secure mobile">
        <div class="g3_footer_payments">
          <p class="g3_footer_title">flexible, secure payment options</p>
          <div class="g3_footer_payments_container">
            <img class="g3_footer_icon"
                 src="/assets/footer/visa.png">
            <img class="g3_footer_icon"
                 src="/assets/footer/mastercard.png">
            <img class="g3_footer_icon"
                 src="/assets/footer/american_express.png">
            <img class="g3_footer_icon"
                 src="/assets/footer/discover.png">
            <img class="g3_footer_icon"
                 src="/assets/footer/paypal.png">
          </div>
        </div>
        <div class="g3_footer_secure">
          <p class="g3_footer_title">guaranteed secure</p>
          <div class="g3_footer_secure_container">
            <img class="g3_footer_icon"
                 src="/assets/footer/chase.png">
            <span class="g3_footer_icon" id="siteseal-mobile">
              <img *ngIf="!isWorkingAdvantage" src="/assets/footer/norton.png">
            </span>
            <img class="g3_footer_icon" src="/assets/footer/credit_safe.png">
          </div>
        </div>
        <div class="g3_footer_back_to_top">
          <button tabindex="99101" (click)="scrollToTop()" class="g3_footer_back_to_top_button">
            Back To Top
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.61295 0.160398C3.61279 0.160563 3.6126 0.160699 3.61243 0.160891L0.768684 3.01842C0.555649 3.2325 0.556442 3.57875 0.770543 3.79184C0.984618 4.0049 1.33087 4.00408 1.54393 3.79001L3.45318 1.87146L3.45318 13.4531C3.45318 13.7552 3.69802 14 4.00006 14C4.3021 14 4.54693 13.7552 4.54693 13.4531L4.54693 1.87149L6.45618 3.78998C6.66925 4.00406 7.0155 4.00488 7.22957 3.79181C7.4437 3.57872 7.44444 3.23244 7.23143 3.01839L4.38768 0.160863C4.38752 0.160699 4.38733 0.160563 4.38716 0.160371C4.17339 -0.053813 3.82602 -0.0531292 3.61295 0.160398Z"
                fill="#757575"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </footer>
  <div class="g3_footer_lock_up">
    <div class="g3_footer_lock_up_container">
      <div  class="g3_footer_lock_up_brands">
        <div *ngIf="brandsRights" class="g3_footer_lock_up_brands_rights">
          <span>{{brandsRights.name}}</span>
        </div>
        <div class="g3_footer_lock_up_brands_images">
          <img *ngIf="isWorkingAdvantage" class="g3_footer_lock_up_brands_image g3_footer_lock_up_brands_image_wa" src="/assets/footer/wa_logo.png">
          <div *ngIf="isWorkingAdvantage" class="g3_footer_lock_up_brands_images_delimiter"></div>
          <img class="g3_footer_lock_up_brands_image g3_footer_lock_up_brands_image_ebg" src="/assets/footer/ebg_logo.png">
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="disclaimer" class="g3_footer_lock_up_disclaimer">
    <div class="g3_footer_lock_up_disclaimer_container">
      <span>{{disclaimer.name}}</span>
    </div>
  </div>
</div>
