/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import {
  Ages,
  RentalCarDropdownSettings,
  Times,
  ValueText
} from './rental-car-options';
import { AutocompleteDropdownSettings, AutocompleteSection } from '@app/shared/components/autocomplete-dropdown/autocomplete-data.interface';
import { ClickOutModel, CustomWidgetModel } from '@app/shared/models/custom-widget.model';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { CustomWidgetComponent } from '../../custom-widget/custom-widget.component';
import { NgbDateCustomParserFormatter } from '@app/widgets/services/datepicker-parser.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { OPACITY_FADE_IN_OUT_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import { RENTAL_CAR_MINI } from '@app/widgets/constants/widget-ids.constant';
import { RentalCarService } from '@app/widgets/services/rental-car.service';
import { SessionStorageService } from '@widgets/services/session-storage.service';
import uniqBy from 'lodash-es/uniqBy';
import { WidgetSuggestionService } from '@widgets/services/widget-suggestion.service';

interface Airport {
  airport_code: string;
  airport_name: string;
}

@Component({
  selector: 'app-rental-car-form',
  templateUrl: './rental-car-form.component.html',
  styleUrls: ['./rental-car-form.component.less'],
  animations: [OPACITY_FADE_IN_OUT_ANIMATION]
})
export class RentalCarFormComponent implements CustomWidgetComponent, OnInit {

  @Input() customClass = '';
  @Input() widgetClass: string;
  @Input() model: CustomWidgetModel;

  @Output() clickOut = new EventEmitter<ClickOutModel>();

  autocompleteDropdownSettings: AutocompleteDropdownSettings = { ...RentalCarDropdownSettings, placeholder: 'City, airport or address' };
  autocompleteDropdownSettingsDropOff: AutocompleteDropdownSettings = { ...RentalCarDropdownSettings, placeholder: 'City, airport or address' };
  autocompleteSections: AutocompleteSection[] = [];
  ages: ValueText[] = Ages;
  times: ValueText[] = Times;
  ageId = 25;
  pickUpTimeId = '12:00';
  dropOffTimeId = '12:00';
  groupTabIndex: number;
  dropOffValue: string;
  pickUpValue: string;
  originName: string;
  destinationName: string;
  airports: Airport[];
  cities;
  url = '';
  isDroppedOff = false;
  destination_not_origin = '';
  closePickUpDropdown: boolean;
  closeDropOffDropdown: boolean;
  cachedAirports: Airport[] = [];
  minDate: NgbDateStruct;
  pickUp: NgbDateStruct;
  dropOff: NgbDateStruct;
  pickUpDate: string;
  dropOffDate: string;

  constructor(
    public datepickerParser: NgbDateCustomParserFormatter,
    private rentalCarService: RentalCarService,
    private sessionStorageService: SessionStorageService,
    private widgetSuggestionService: WidgetSuggestionService
  ) {
    this.minDate = datepickerParser.toNgbDate(new Date());
    this.pickUp = datepickerParser.toNgbDate(new Date());
    this.dropOff = datepickerParser.toNgbDate(new Date(), 2);
  }

  ngOnInit(): void {
    this.intialValues();
    this.handleStoredData();
  }

  async getAutocompleteValue(value: string): Promise<void> {
    const responseData = await this.rentalCarService.getRentalCarAutocompleteData(value, this.model.rentalCarHostUrl);
    const mappedResponse = responseData && responseData.getCarAutoComplete && responseData.getCarAutoComplete.results
      ? this.rentalCarService.mapResponseData(responseData)
      : {};

    this.airports = mappedResponse && mappedResponse.airport_data ? mappedResponse.airport_data : [];
    this.cities = mappedResponse && mappedResponse.city_data ? mappedResponse.city_data : [];
    this.airports = [
      ...this.rentalCarService.getCitiesAirports(this.cities) as Airport[],
      ...this.airports
    ];
    this.cachedAirports = uniqBy([...this.airports, ...this.cachedAirports], 'airport_code');
    this.cachedAirports
      .filter(airport => airport.airport_name.toLowerCase().includes(value.toLowerCase()))
      .forEach(airport => {
        this.airports.push(airport);
      });
    this.airports = uniqBy([...this.airports], 'airport_code');
    this.autocompleteSections = this.rentalCarService.getAutocompleteSections(this.airports, this.cities);
  }

  handlePickUpAutocomplete(): void {
    this.closePickUpDropdown = false;
    this.closeDropOffDropdown = true;
  }

  handleDropOffAutocomplete(): void {
    this.closeDropOffDropdown = false;
    this.closePickUpDropdown = true;
  }

  getPickUpValue(value: string): void {
    this.pickUpValue = value;
    this.originName = this.getDestinationPlace(value);
  }

  getDropOffValue(value: string): void {
    this.dropOffValue = value;
    this.destinationName = this.getDestinationPlace(value);
  }

  getDestinationPlace(value: string): string {
    return this.rentalCarService.getDestinationPlace(value, this.airports, this.cities);
  }

  dropOffChange(value: boolean): void {
    this.isDroppedOff = value;
    this.destination_not_origin = value ? 'on' : '';
  }

  selectChange({ name, value }): void {
    if (name === 'pickUpTimeId') {
      this.pickUpTimeId = value;
    }

    if (name === 'dropOffTimeId') {
      this.dropOffTimeId = value;
    }

    if (name === 'age') {
      this.ageId = value;
    }
  }

  search(): void {
    this.pickUpDate = this.datepickerParser.convertDateToFormat(this.datepickerParser.format(this.pickUp));
    this.dropOffDate = this.datepickerParser.convertDateToFormat(this.datepickerParser.format(this.dropOff));
    this.dispatchWidgetData();
    this.clickOut.emit({ model: this.model, url: this.url });
  }

  onDateSelection(date: NgbDateStruct, target: string): void {
    if (target === 'pickUp') {
      this.dropOff = this.datepickerParser.toNgbDate(new Date(this.datepickerParser.format(date)), 3);
      this.pickUp = date;
    } else {
      this.dropOff = date;
    }
  }

  private dispatchWidgetData(): void {
    this.sessionStorageService.removeGroupOfKeys('_mini');
    const data = {
      pickUpValue: this.pickUpValue,
      originName: this.originName,
      dropOffValue: this.dropOffValue,
      destinationName: this.destinationName,
      pickUpTimeId: this.pickUpTimeId,
      dropOffTimeId: this.dropOffTimeId,
      ageId: this.ageId,
      destination_not_origin: this.destination_not_origin,
      pickUp: new Date(this.datepickerParser.format(this.pickUp)),
      dropOff: new Date(this.datepickerParser.format(this.dropOff))
    };
    this.sessionStorageService.setItem(RENTAL_CAR_MINI, data);
    void this.widgetSuggestionService.addSuggestedWidgets({
      data,
      type: RENTAL_CAR_MINI,
      page_url: window.location.href,
      destination_url: this.url
    });

  }

  private handleStoredData(): void {
    const parsedRentalCarWidgetData = this.sessionStorageService.getItem<any>(RENTAL_CAR_MINI);

    if (parsedRentalCarWidgetData) {
      if (parsedRentalCarWidgetData.pickUpValue) {
        this.pickUpValue = parsedRentalCarWidgetData.pickUpValue;
      }

      if (parsedRentalCarWidgetData.originName) {
        this.autocompleteDropdownSettings.inputString = parsedRentalCarWidgetData.originName;
        this.originName = parsedRentalCarWidgetData.originName;
      }

      if (parsedRentalCarWidgetData.dropOffValue) {
        this.dropOffValue = parsedRentalCarWidgetData.dropOffValue;
      }

      if (parsedRentalCarWidgetData.destinationName) {
        this.autocompleteDropdownSettingsDropOff.inputString = parsedRentalCarWidgetData.destinationName;
        this.destinationName = parsedRentalCarWidgetData.destinationName;
      }

      if (parsedRentalCarWidgetData.pickUpTimeId) {
        this.pickUpTimeId = parsedRentalCarWidgetData.pickUpTimeId;
      }

      if (parsedRentalCarWidgetData.dropOffTimeId) {
        this.dropOffTimeId = parsedRentalCarWidgetData.dropOffTimeId;
      }

      if (parsedRentalCarWidgetData.ageId) {
        this.ageId = parsedRentalCarWidgetData.ageId;
      }

      if (parsedRentalCarWidgetData.destination_not_origin) {
        this.isDroppedOff = parsedRentalCarWidgetData.destination_not_origin === 'on';
        this.destination_not_origin = parsedRentalCarWidgetData.destination_not_origin;
      }

      if (parsedRentalCarWidgetData.pickUp) {
        this.pickUp = this.datepickerParser.toNgbDate(this.datepickerParser.handleTimezoneDiff(parsedRentalCarWidgetData.pickUp));
      }

      if (parsedRentalCarWidgetData.dropOff) {
        this.dropOff = this.datepickerParser.toNgbDate(this.datepickerParser.handleTimezoneDiff(parsedRentalCarWidgetData.dropOff));
      }
    }
  }

  private intialValues(): void {
    if (this.model.destinationUrl) {
      this.url = this.model.destinationUrl;
    }
  }

}
