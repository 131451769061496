import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '@app/shared/services/modal.service';
import { ContactTypes } from '@app/shared/services/one-time-password.service';
import { OtpReasonTypes } from 'g3-common-ui';

@Component({
  selector: 'app-vault-otp-modal',
  templateUrl: './vault-otp-modal.component.html',
  styleUrls: ['./vault-otp-modal.component.less']
})
export class VaultOtpModalComponent {

  @Input() type: ContactTypes;
  @Input() contact: string;
  @Input() isContactEditable = true;
  @Input() sendImmediately = false;
  @Input() otpReasonType: OtpReasonTypes;
  @Input() guid?: string;
  @Input() isChild?: boolean;

  @Output() onValidCode = new EventEmitter<string>(); // eslint-disable-line

  constructor(private modalService: ModalService) { }

  public closeModal(): void {
    this.modalService.closeModal('close');
  }

  onFormCodeValid(code: string): void {
    this.onValidCode.emit(code);
    this.modalService.closeModal('close');
  }
}
