import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { environment } from '@environments/environment';

// Angulartics
import { Angulartics2Module } from 'angulartics2';

// NgBootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';

// Core
import { CoreModule } from '@app/core/core.module';
import { AuthGuardService as AuthGuard } from '@app/core/auth/services/auth-guard.service';
import { AuthService } from '@app/core/auth/services/auth.service';
import { AuthInterceptor } from '@app/core/auth/services/auth.interceptor';
import { TokenInterceptor } from '@app/core/auth/services/token.interceptor';

// Analytics
import { G3analyticsService } from '@app/shared/services/g3analytics.service';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { AdobeAnalyticsLaunchService } from './shared/services/adobe/adobe-analytics-launch.service';
import { AdobeDataCollectorService } from './shared/services/adobe/adobe-data-collector.service';
import * as AdobeUnits from './shared/services/adobe/units/index';

// Shared
import { SharedModule } from '@app/shared/shared.module';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { ConfigService } from '@app/shared/services/config.service';
import { DocumentService } from '@app/shared/services/document.service';
import { OffersService } from '@app/shared/services/offers.service';
import { ViewSettingsService } from '@app/shared/services/view-settings.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PermissionService } from '@core/auth/services/permission.service';
import { ContactFormService } from '@shared/services/contact-form.service';
import { PoliciesService } from '@policies/services/policies.service';
import { KnownUserService } from '@shared/services/known-user.service';

import { ZonesModule } from '@app/zones/zones.module';

import { ModalService } from './shared/services/modal.service';

// Brands
import { ThemeStylesService } from '@shared/services/theme-styles.service';

import { UrlHelperService } from './core/auth/services/url-helper.service';
import { AppRoutingModule } from './app-routing.module';

import { NpsSurveyModule } from './nps-survey/nps-survey.module';
import { HomepageModule } from './homepage/homepage.module';
import { AdsOffersService } from '@shared/services/ads/ads-offers.service';
import { ProfileFeaturesGuardService } from './core/auth/services/profile-features-guard.service';
import { SubscriptionCenterModule } from './subscription-center/subscription-center.module';
import { CustomUrlSerializer } from './custom-url-serializer.class';
import { UrlSerializer } from '@angular/router';
import { SubscriptionsGuardService } from './core/auth/services/subscriptions-guard.service';
import { InfiniteScrollService } from './shared/services/infinite-scroll.service';
import { CorporateRedirectService } from '@core/auth/services/corporate-redirect.service';
import { AccountConfirmationService } from '@shared/services/account-confirmation.service';
import { PillsService } from '@app/shared/services/pills.service';
import {
  CloudflareInterceptor,
  G3CommonUiModule,
  FocusEmitterService,
  CONFIG_SERVICE,
  TargetCookiesInterceptor
} from 'g3-common-ui';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
        idsRegExp: new RegExp('^[a-z]\\d+$') /* Workaround: No NgModule metadata found for 'AppModule' */,
        clearQueryParams: true,
        clearHash: true
      }
    }),
    G3CommonUiModule.forRoot({
      environment,
      // TODO: Remove when all apps migrate to dynamic OneTrust support
      enableOneTrustBlocking: true
    }, [
      {
        provide: CONFIG_SERVICE,
        useExisting: ConfigService
      },
    ]),
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CoreModule,
    NgbModule,
    SharedModule.forRoot(),
    NpsSurveyModule,
    HomepageModule,
    ZonesModule,
    SubscriptionCenterModule,
    HammerModule
  ],
  declarations: [AppComponent],
  providers: [
    OffersService,
    AdsOffersService,
    ViewSettingsService,
    PermissionService,
    AuthService,
    InfiniteScrollService,
    AuthGuard,
    AdobeAnalyticsLaunchService,
    AccountConfirmationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CloudflareInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TargetCookiesInterceptor,
      multi: true
    },
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService, config: ConfigService, themeService: ThemeStylesService) => async (): Promise<void> => {
        await config.onInit();
        await Promise.allSettled([
          authService.tryLogin(),
          themeService.getStyles(),
        ]);
      },
      deps: [AuthService, ConfigService, ThemeStylesService],
      multi: true
    },
    AdobeDataCollectorService,
    ...AdobeUnits.AdobeUnitsServices,
    DocumentService,
    G3analyticsService,
    AnalyticsService,
    BreadcrumbsService,
    ContactFormService,
    FocusEmitterService,
    UrlHelperService,
    ProfileFeaturesGuardService,
    SubscriptionsGuardService,
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    PoliciesService,
    KnownUserService,
    CorporateRedirectService,
    PillsService,
    ModalService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}
