import { Ad, AdSource } from '@shared/models/ad.model';
import sample from 'lodash-es/sample';
import {
  AnalyticsAdEventModel,
  AnalyticsEvent,
  AnalyticsGAEventModel,
  AnalyticsInternalEventModel
} from '@shared/models/analytics.event.model';
import { BehaviorSubject } from 'rxjs';
import { getAnalyticsActionByZoneSectionElement, getAnalyticsGACategoryByZoneSectionElement, getAnalyticsGALabelFromObject } from '@zones/services/analytics/helpers';
import { Injectable } from '@angular/core';
import { NotiBlockItem } from '@zones/interfaces/noti-block.interface';
import { ProfileService } from '@shared/services/profile/profile.service';
import { Router } from '@angular/router';
import { UrlHelperService } from 'g3-common-ui';
import { ZoneSectionElement } from '@zones/interfaces/zones-search-response.interface';

type ActionType = 'displayed'
| 'click'
| 'dismiss';

@Injectable({
  providedIn: 'root'
})
export class NotiBlockService {

  notiBlockStateSource = new BehaviorSubject<boolean>(false);
  notiBlockState$ = this.notiBlockStateSource.asObservable();

  notiBannerStateSource = new BehaviorSubject<boolean>(false);
  notiBannerState$ = this.notiBannerStateSource.asObservable();

  constructor(
    private profileService: ProfileService,
    private router: Router,
    private urlHelper: UrlHelperService
  ) { }

  getNotiBlock(zoneSectionElement: ZoneSectionElement): NotiBlockItem {
    const notiAdInfo = zoneSectionElement && zoneSectionElement.ads ? zoneSectionElement.ads[0] : null;
    return notiAdInfo ? this.getContent(notiAdInfo, zoneSectionElement.type) : null;
  }

  getContent(notiAdInfo: Ad, style: string): NotiBlockItem {
    const itemInfo = {
      guid: notiAdInfo.guid,
      ad_id: notiAdInfo.id,
      ad_source: notiAdInfo.source,
      style
    } as NotiBlockItem;

    const itemContent: NotiBlockItem = notiAdInfo.content.reduce((obj, item) => {
      obj[item['key']] = item['value'];
      return obj;
    }, {} as NotiBlockItem);

    itemInfo.isEbg = notiAdInfo.isEbg;

    // background_url is array of strings instead of a plain string
    if (itemContent.background_url) {
      itemContent.background_url = sample(itemContent.background_url);
    }

    return { ...itemInfo, ...itemContent };
  }

  isItemAvailablePerSession(notiBlockItem: NotiBlockItem): boolean {
    const notiBlockItemKey = `noti-block-${notiBlockItem.guid}-closed`;
    const isNotiAdDismissed = sessionStorage.getItem(notiBlockItemKey);
    // if dismiss value is session and user has dismissed ad - do not show per session
    return !(notiBlockItem.dismiss === 'session' && isNotiAdDismissed);
  }

  async handleDismiss(notiBlockItem: NotiBlockItem): Promise<void> {
    switch (notiBlockItem.dismiss) {
      case 'session':
        // do not show per session
        const notiBlockItemKey = `noti-block-${notiBlockItem.guid}-closed`;
        sessionStorage.setItem(notiBlockItemKey, 'true');
        break;
      case 'perm':
        // set ad to dismissed
        const expiredDate = { amount: 90, units: 'days' };
        await this.profileService.updateDismissedDecisions(notiBlockItem.guid, expiredDate).toPromise();
        break;
    }
  }

  handleButtonClick(notiBlockItem: NotiBlockItem): void {
    const isExternalUrl = this.urlHelper.isExternalUrl(notiBlockItem.button_url);
    const windowTarget = notiBlockItem.isEbg ? '_self' : '_blank';

    if (isExternalUrl) {
      window.open(notiBlockItem.button_url, windowTarget);
    } else {
      this.router.navigateByUrl(notiBlockItem.button_url);
    }
  }

  getAnalyticsEvents(action: ActionType, zoneSectionElement: ZoneSectionElement, notiBlockItem: NotiBlockItem): AnalyticsEvent[] {
    let result;
    let data;
    const analyticsAction = getAnalyticsActionByZoneSectionElement(zoneSectionElement, action);

    switch (action) {
      case 'displayed':
        data = { ad_guid: notiBlockItem.guid };
        if (zoneSectionElement.name) {
          data['name'] = zoneSectionElement.name;
        }
        result = [
          new AnalyticsInternalEventModel(analyticsAction, data),
          new AnalyticsGAEventModel(analyticsAction, {
            category: getAnalyticsGACategoryByZoneSectionElement(zoneSectionElement),
            label: getAnalyticsGALabelFromObject(data)
          })
        ];
        if (notiBlockItem.ad_source === AdSource.BSS) {
          result.push(new AnalyticsAdEventModel('g3-ad-results', {
            date_time: new Date(),
            ads: [{ response_id: notiBlockItem.ad_id }]
          }));
        }
        return result;
      case 'click':
        if (notiBlockItem.button_url) {
          data = {
            ad_guid: notiBlockItem.guid,
            type: notiBlockItem.button_action,
            url: notiBlockItem.button_url || ''
          };
        } else {
          data = { ad_guid: notiBlockItem.guid, type: 'close' };
        }
        data.click_out = notiBlockItem.isEbg || !this.urlHelper.isExternalUrl(notiBlockItem.button_url) ? 'internal' : 'external';

        result = [
          new AnalyticsInternalEventModel(analyticsAction, data),
          new AnalyticsGAEventModel(analyticsAction, {
            category: getAnalyticsGACategoryByZoneSectionElement(zoneSectionElement),
            label: getAnalyticsGALabelFromObject(data)
          })
        ];
        // if this ad is from BSS and it's a click
        if (notiBlockItem.ad_source === AdSource.BSS) {
          result.push(new AnalyticsAdEventModel('g3-ad-click', {
            date_time: new Date(),
            response_id: notiBlockItem.ad_id
          }));
        }
        return result;
      case 'dismiss':
        data = { ad_guid: notiBlockItem.guid };
        return [
          new AnalyticsInternalEventModel(analyticsAction, data),
          new AnalyticsGAEventModel(analyticsAction, {
            category: getAnalyticsGACategoryByZoneSectionElement(zoneSectionElement),
            label: getAnalyticsGALabelFromObject(data)
          })
        ];
    }
  }

  updateNotiBlockState(data: boolean): void {
    this.notiBlockStateSource.next(data);
  }

  updateNotiBannerState(data: boolean): void {
    this.notiBannerStateSource.next(data);
  }
}
