/* eslint-disable eqeqeq */
import { Pipe, PipeTransform } from '@angular/core';
import { PaylogixAmount } from '@shared/enums/paylogix-amount.enum';
import isNil from 'lodash-es/isNil';

export const PAYMENT_AMOUNT_VARIES = 'varies';
export const PAYMENT_VARIES_DISPLAY = 'Varies';

@Pipe({
  name: 'paylogix'
})
export class PaylogixPipe implements PipeTransform {

  constructor() { }

  transform(rangeOfValues: string[], amountType?: PaylogixAmount): string {
    const rangeValues = (firstValue: number | string, secondValue: number | string): boolean =>
      (rangeOfValues[0] == firstValue && rangeOfValues[1] == secondValue)
      || (rangeOfValues[1] == firstValue && rangeOfValues[0] == secondValue);

    if (rangeOfValues.length === 1) {
      const { value, attachment } = this.getBenefitValue(rangeOfValues[0]);

      if (value && value !== PAYMENT_AMOUNT_VARIES) {
        if (!!attachment) {
          return `$${value}${attachment}`;
        }
        return `$${Number(value).toFixed(2)}`;
      }

      return `${value}${attachment}`;
    }

    if (rangeValues(PAYMENT_AMOUNT_VARIES, 0)) {
      return PAYMENT_VARIES_DISPLAY;
    }

    let result = '';

    switch (amountType) {
      case PaylogixAmount.Benefit:
      case PaylogixAmount.Premium:
        result = this.getOfferDescriptionInfo(rangeOfValues[0], rangeOfValues[1]);
        break;
    }

    return result;
  }

  private getValueAsNumber(value: string): number {
    /*
      Removing currency formatting before converting to a number (possible formats: '$50k', '50000.00', 'Varies')
      If value is a non-numeric string we shouldn't remove anything
      If value is not a number return -1 for next comparison
    */
    const hasCurrencyFormat = /^\$.*k$/igm.test(value);
    const valueAsNumber = hasCurrencyFormat ? +(value.replace(/^\$|\k$/g, '')) * 1000 : +value;
    return isNaN(valueAsNumber) ? -1 : valueAsNumber;
  }

  private getBenefitValue(value: number | string): { value: number | string; attachment: string } {
    const benefitValue = +value;

    if (value && !isNaN(benefitValue)) {
      if (benefitValue < 1000) {
        return { value: Number((benefitValue).toFixed(2)), attachment: '' };
      }

      const benefit = Math.floor(benefitValue / 1000);
      return { value: Math.trunc(benefit), attachment: 'k' };
    }

    return { value: value.toString().toLowerCase(), attachment: '' };
  }

  private getIsRangeVariesOrZero(min: number | string, max: number | string): boolean {
    return (min === PAYMENT_AMOUNT_VARIES && max === 0)
      || (min === 0 && max === PAYMENT_AMOUNT_VARIES);
  }

  private getOfferDescriptionInfo(min: number | string, max: number | string): string {
    const { value: amountMin, attachment: amountMinAttachment } = this.getBenefitValue(min);
    const { value: amountMax, attachment: amountMaxAttachment } = this.getBenefitValue(max);
    const multiplierMin = amountMinAttachment && 1000 || 1;
    const multiplierMax = amountMaxAttachment && 1000 || 1;
    const amountVariesOrZero = this.getIsRangeVariesOrZero(amountMin, amountMax);
    const convertedMin = this.convertNumberFormat(amountMin as string, amountMinAttachment);
    const convertedMax = this.convertNumberFormat(amountMax as string, amountMaxAttachment);

    let description = '';

    const benefitRangeAllowed =
      (amountMin as number > 0 || amountMin === PAYMENT_AMOUNT_VARIES) &&
      (amountMax as number > 0 || amountMax === PAYMENT_AMOUNT_VARIES) &&
      amountMin !== amountMax;

    if (amountMin && !amountMax && !amountVariesOrZero) {
      description += `from $${convertedMin}${amountMinAttachment}`;
    }

    if (!amountMin && amountMax && amountMax !== PAYMENT_AMOUNT_VARIES) {
      description += `up to $${convertedMax}${amountMaxAttachment}`;
    }

    if (benefitRangeAllowed) {
      let info = `$${convertedMin}${amountMinAttachment} - $${convertedMax}${amountMaxAttachment}`;

      if ((amountMin as number) * multiplierMin >= (amountMax as number) * multiplierMax) {
        info = `from $${convertedMax}${amountMaxAttachment}`;
      }
      if (amountMin === PAYMENT_AMOUNT_VARIES) {
        info = `${PAYMENT_VARIES_DISPLAY} - $${convertedMax}${amountMaxAttachment}`;
      }
      if (amountMax === PAYMENT_AMOUNT_VARIES) {
        info = `$${convertedMin}${amountMinAttachment} - ${PAYMENT_VARIES_DISPLAY}`;
      }

      description += info;
    }

    const isBenefitMin = !isNil(amountMin) && amountMin !== '';
    if (isBenefitMin && amountMin === amountMax) {
      let info = `$${convertedMin}${amountMinAttachment}`;

      if (amountMin === PAYMENT_AMOUNT_VARIES) {
        info = `${PAYMENT_VARIES_DISPLAY}`;
      }

      if (amountMin === 0) {
        info = '';
      }

      description += info;
    }

    if (amountVariesOrZero) {
      description += `${PAYMENT_VARIES_DISPLAY}`;
    }

    return description;
  }

  private convertNumberFormat(value: string, attachment: string): string {
    if (attachment) {
      return value;
    }

    const asNumber = Number(value);

    return asNumber && asNumber.toFixed(2) || value;
  }

}
