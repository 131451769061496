import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AnalyticsGAEventModel, AnalyticsGAOfferModel, AnalyticsInternalEventModel } from '@shared/models/analytics.event.model';
import { AnalyticsService } from '@shared/services/analytics.service';
import { ConfigService } from '@shared/services/config.service';
import { ModalPassThruModel } from '@app/shared/components/modal-pass-thru/modal-pass-thru.model';
import { ModalService } from '@app/shared/services/modal.service';
import { OfferType } from '@shared/models/offer.type';
import { OfferModel } from '@shared/models/offer.model';
import { TodayDeal } from '@shared/models/today-deal.model';
import {
  AdobeEventNameEnum,
  AdobeEventTypeEnum
} from '@shared/services/adobe/adobe-analytics.interfaces';
import { AdobeAnalyticsEventsService } from '@shared/services/adobe/units/adobe-analytics-events.service';

@Component({
  selector: 'app-modal-pass-thru',
  templateUrl: './modal-pass-thru.component.html',
  styleUrls: ['./modal-pass-thru.component.less']
})
export class ModalPassThruComponent implements OnInit {
  @Input() item: ModalPassThruModel;

  public displayModal = false;

  constructor(
    public modalService: ModalService,
    public configService: ConfigService,
    private analyticsService: AnalyticsService,
    private readonly route: ActivatedRoute,
    private readonly adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
  ) {
  }

  ngOnInit(): void {
    // * Auth offer internal click-out
    if (this.route.snapshot.queryParams['skip_pass_thru'] === 'true') {
      this.goToSite();
    } else {
      this.displayModal = true;
    }

    this.trackEvent('pass-thru-view');
  }

  goToSite(): void {
    this.trackEvent('pass-thru-click-out');
    this.modalService.closeModal('close');
  }

  async trackEvent(eventAction: string): Promise<void> {
    const eventData = {
      guid: this.item.guid,
      brand: this.item.brand,
      header: this.item.header
    };
    let label = new AnalyticsGAOfferModel(this.item.guid, this.item.brand, this.item.header).generateOfferLabel();

    if (eventAction === 'pass-thru-click-out') {
      eventData['click_out'] = this.item.isInternal ? 'internal' : 'external';
      label += ` | ${eventData['click_out']}`;
    }

    const internalAnalytics = new AnalyticsInternalEventModel(eventAction, eventData);
    const googleAnalytics = new AnalyticsGAEventModel(eventAction, { category: 'pass-thru', label });

    this.analyticsService.eventsTrack([internalAnalytics, googleAnalytics]);

    if (eventAction === 'pass-thru-copy-code') {
      if (this.item.offerType === OfferType.Offer) {
        const offerData = this.item.originalData as OfferModel;
        const adobeData = this.adobeAnalyticsEventsService.getOfferAdobeDataFromOffer(offerData, AdobeEventNameEnum.EMAIL_MODAL, 'offer');
        delete adobeData.offer.size;
        void this.adobeAnalyticsEventsService.sendOfferClick({
          event_name: AdobeEventNameEnum.EMAIL_MODAL,
          event_type: AdobeEventTypeEnum.COPY_CODE,
          properties: adobeData
        });
      } else {
        const tddData = this.item.originalData as TodayDeal;
        const adobeData = await this.adobeAnalyticsEventsService.getOfferAdobeDataFromTodayDeal(tddData , AdobeEventNameEnum.EMAIL_MODAL, 'offer');
        if (adobeData.offer.isEbg) {
          delete adobeData.offer.shopping_category;
        }
        delete adobeData.offer.isEbg;
        this.adobeAnalyticsEventsService.emitGeneralEvent({
          event_name: AdobeEventNameEnum.EMAIL_MODAL,
          event_type: AdobeEventTypeEnum.COPY_CODE,
          properties: adobeData
        });
      }
    }
  }
}
