/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
/* eslint-disable radix */
import { BehaviorSubject, Observable, from } from 'rxjs';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import capitalize from 'lodash-es/capitalize';
import { environment } from '@environments/environment';
import { filter, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ZoneSection, ZoneSectionElement, ZonesSearchResponse } from '@zones/interfaces/zones-search-response.interface';
import { ZonesSectionsService } from '@zones/services/zones-sections.service';
import { PermissionService } from '@core/auth/services/permission.service';
import { KnownUserService } from '@shared/services/known-user.service';

export enum ZoneElementTypes {
  NotiBlock = 'noti_block',
  NotiStrip = 'noti_strip',
  CardsFixed = 'cards_fixed',
  CardsScroll = 'cards_scroll',
  WidgetsMini = 'widgets_mini',
  WidgetsLarge = 'widgets_large',
  CtaBlock = 'cta_block',
  CtaProduct = 'cta_product',
  CardsProduct = 'cards_product',
  TddScroll = 'tdd_scroll',
  TopBrands = 'brand_logos',
  TopCategories = 'category_names_scroll',
  TopBrandsNames = 'brand_names_scroll',
}

export const PRODUCT_CARD = 'Product';
export const allZonesToLoad = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'];

const HOMEPAGE = 'homepage';

@Injectable({
  providedIn: 'root'
})
export class ZonesService {

  zonesSectionsSource = new BehaviorSubject<ZoneSection[]>([]);
  zonesSections$ = this.zonesSectionsSource.asObservable();
  zonesPromises: Map<string, Promise<ZonesSearchResponse>[]> = new Map();

  private loadedZones: Map<string, ZonesSearchResponse> = new Map();

  constructor(
    private http: HttpClient,
    private zonesSectionsService: ZonesSectionsService,
    private permissionService: PermissionService,
    private knownUserService: KnownUserService
  ) { }

  debug(zoneSection: ZoneSection, value: string): void {
    console.info(`Zone Section (${zoneSection ? capitalize(zoneSection.page) : ''} | ${zoneSection ? zoneSection.section : ''}) : ${value}`);
  }

  async getAvailableZoneSections(page: string, filter?: { country_last: string}): Promise<string[]> {
    const queryString = this.getQueryString(null, null, filter?.country_last);
    return this.http.get<string[]>(`${environment.apiUrl}/api/zones/${page}/sections${queryString ? '?' + queryString : ''}`).toPromise();
  }

  async getZonesData(page: string, queryString: string): Promise<ZonesSearchResponse> {
    if (this.loadedZones.has(queryString)) {
      return this.loadedZones.get(queryString);
    }

    const zoneRequest = this.http.get<ZonesSearchResponse>(`${environment.apiUrl}/api/zones/search?${queryString}`).pipe(
      tap(data => this.loadedZones.set(queryString, data)),
    ).toPromise();

    await this.waitForPreviousZone(page, zoneRequest);

    return zoneRequest.then(zonesData => {
      for (const zoneItem of zonesData?.items) {
        zoneItem.elements = this.sortZoneSectionElements(zoneItem.elements);
      }
      return zonesData;
    });
  }

  async waitForPreviousZone(page: string, zoneRequest: Promise<ZonesSearchResponse>): Promise<void> {
    if (!this.zonesPromises.has(page)) {
      this.zonesPromises.set(page, []);
    }

    const pagePromises = this.zonesPromises.get(page);
    const previousRequest = pagePromises[pagePromises.length - 1];
    pagePromises.push(zoneRequest);

    if (previousRequest !== undefined) {
      await previousRequest.catch(() => {});
    }
  }

  async loadZoneSection(page: string, zoneSection: ZonesSearchResponse): Promise<void> {
    await this.initZoneSectionElements(page, zoneSection.items[0]);
  }

  async getZones(page: string, sections?: string[]): Promise<ZonesSearchResponse> {
    const queryString = this.getQueryString(page, sections);
    const zonesData = await this.getZonesData(page, queryString);

    void this.initZoneSections(page, zonesData);

    return zonesData;
  }

  async initZoneSections(page: string, zonesData: ZonesSearchResponse): Promise<void> {
    for (const zoneSection of zonesData.items) {
      await this.initZoneSectionElements(page, zoneSection);
    }
  }

  public async initZoneSectionElements(page: string, zoneSection: ZoneSection): Promise<void> {
    if (!zoneSection) {
      return;
    }

    const currentValue = this.zonesSectionsSource.getValue();
    // if there is not such section in local data
    if (!currentValue.find((i: ZoneSection) => i.page === page && i.section === zoneSection.section)) {
      // we need to sort elements by priority
      zoneSection.elements = this.sortZoneSectionElements(zoneSection.elements);
      // fetch data for zone section
      zoneSection = await this.zonesSectionsService.getZoneSectionData(zoneSection);
      currentValue.push(zoneSection);
    }

    this.zonesSectionsSource.next(currentValue);
  }

  getZonesSectionsForPage(page: string): Observable<ZoneSection[]> {
    return this.zonesSections$
      .pipe(
        map(items => items.filter(i => i.page === page)),
        filter(items => items.length > 0)
      );
  }

  getQueryString(page: string | string[], section?: string | string[], countryLast?: string): string {
    const result = [];
    if (page) {
      result.push(`page=${Array.isArray(page) ? page.join(',') : page}`);
      if (section) {
        result.push(`section=${Array.isArray(section) ? section.join(',') : section}`);
      }
    }

    if (countryLast) {
      result.push(`country_last=${countryLast}`);
    }

    if (this.permissionService.hasDefined('known:access')) {
      result.push(`known_user_email=${this.knownUserService.knowUserEmail}`);
    }

    return result.join('&');
  }

  sortZoneSectionElements(items: ZoneSectionElement[] = []): ZoneSectionElement[] {
    return items.sort((a, b) => {
      const priorityA = this.getPriorityNumber(a.priority);
      const priorityB = this.getPriorityNumber(b.priority);
      return (priorityA < priorityB) ? 1 : ((priorityA > priorityB) ? -1 : 0);
    });
  }

  getPriorityNumber(value: string): number {
    // we need to get first number value in the string
    const defaultValue = 0;
    return value ? (value.split(',').map(i => {
      const asNumber = parseInt(i); return isNaN(asNumber) ? null : asNumber;
    }).filter(i => i !== null))[0] || defaultValue : defaultValue;
  }

  updateAd(guid: string, data: { [key: string]: any }): void {
    const currentValue: ZoneSection[] = this.zonesSectionsSource.getValue();
    this.zonesSectionsSource.next(currentValue.map(i => {
      i.elements = i.elements.map(j => {
        j.ads = j.ads.map(ad => {
          if (ad.contentGuid === guid) {
            Object.keys(data).forEach(field => {
              if (ad.hasOwnProperty(field)) {
                ad[field] = data[field];
              }
            });
          }
          return ad;
        });
        return j;
      });
      return i;
    }));
  }

  async getZoneElementByQueryParam(page: string, queryParams: Params): Promise<ZoneSectionElement> {
    let selectedZoneElement = null;

    if (queryParams.zone_id) {
      const queryString = this.getQueryString(HOMEPAGE, allZonesToLoad);
      try {
        const zones = await this.getZonesData(page, queryString);
        const zoneItems = get(zones, 'items', []);
        const filterZones = zoneItems.find(zone => zone.elements.find(element => element.guid === queryParams.zone_id));
        const zoneElementsData = await this.zonesSectionsService.getZoneSectionData(filterZones);

        if (!isEmpty(zoneElementsData)) {
          const getSelectedZoneElement = zoneElementsData.elements.find(element => element.guid === queryParams.zone_id) || null;
          if (getSelectedZoneElement && this.isAllowedToPin(getSelectedZoneElement.type)) {
            selectedZoneElement = getSelectedZoneElement;
          }

        }
      } catch (err) {
        console.error('Cannot process zones', err);
      }
    }

    return selectedZoneElement;
  }

  isAllowedToPin(type: string): boolean {
    return type === ZoneElementTypes.CardsFixed || type === ZoneElementTypes.CardsScroll
      || type === ZoneElementTypes.TddScroll || type === ZoneElementTypes.CardsProduct;
  }

}
