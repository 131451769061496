/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { Ad } from '@app/shared/models/ad.model';
import { AdDisclaimerModalComponent } from '../ad-disclaimer-modal/ad-disclaimer-modal.component';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DirectClickOutService } from '@app/shared/services/direct-click-out.service';
import {
  imageOptions,
  imageOptionsWithInput,
  logoOptionsLarge,
  logoOptionsMedium,
  logoOptionsSmall
} from './image-options';
import { MyOffersService } from '@app/my-profile/services/my-offers.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ShareInfoService } from '@app/shared/services/share-info.service';
import {
  ImageUrlPipeOptions,
  KnownUserType,
  WINDOW,
  WindowHelper,
} from 'g3-common-ui';
import { ProfileService } from '@app/shared/services/profile/profile.service';
import { KnownUserService } from '@app/shared/services/known-user.service';
import { PaylogixService } from '@shared/services/paylogix/paylogix.service';
import { AdItemClick } from '@app/shared/enums/ad-item-click.enum';
import { ClickOutService } from '@app/shared/services/click-out.service';
import { IAdCTAButtonOptions } from '@app/shared/interfaces/ad-cta-button-options.interface';
import { OffersAnalyticsService } from '@app/shared/services/analytics/offers-analytics.service';
import { AccountConfirmationService } from '@shared/services/account-confirmation.service';
import { PaylogixAmount } from '@app/shared/enums/paylogix-amount.enum';
import { OpenEnrollmentService } from '@shared/services/open-enrollment/open-enrollment.service';
import get from 'lodash-es/get';
import { distinctUntilChanged } from 'rxjs/operators';
import { EXTRA_CAT_CODE_TAG_ID_PAYROLL } from '@app/shared/constants/cat-code-tags.constants';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';
import { AdobeCardSizeEnum, AdobeEventNameEnum, AdobeEventTypeEnum } from '@shared/services/adobe/adobe-analytics.interfaces';
import { ENROLLED_TITLES } from '@app/shared/constants/paylogix.constants';

export interface AdCtaClick {
  ad: Ad;
  source: string;
  params?: Record<string, string | number | undefined>;
}

type CardsSourceType = 'cards_fixed' | 'cards_scroll' | 'cards_items_rows';

@Component({
  selector: 'app-ad-item',
  templateUrl: './ad-item.component.html',
  styleUrls: [
    './ad-item.component.less',
    './product-card.less',
    './paylogix-enrollment.less',
    './pills.less'
  ]
})
export class AdItemComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() public ad: Ad;
  @Input() public isTier1 = false;
  @Input() public favoriteFromEventName = 'favorite-from-ad';
  @Input() public groupTabIndex: number;
  @Input() public isAllowDisableHover = true;
  @Input() public parentType: CardsSourceType;
  @Input() public isEnrollment = false;
  @Input() public isSearchResult = false;
  @Input() public forceStandardCardTemplate = false;
  @Input() public zoneName: string;

  @Output() public viewInit: EventEmitter<boolean | string> = new EventEmitter(false);
  @Output() public displayed: EventEmitter<Ad> = new EventEmitter<Ad>();
  @Output() public clicked: EventEmitter<Ad> = new EventEmitter<Ad>();
  @Output() public clickedCta: EventEmitter<AdCtaClick> = new EventEmitter<AdCtaClick>();

  @HostBinding('attr.role') public role = 'link';
  @HostBinding('attr.aria-label') public ariaLabel: string;
  @HostBinding('class.disable-hover') public disableHover = false;
  @HostBinding('class.card-input-enabled') public cardInputEnabled = false;

  @ViewChild('inputCardButton') public inputCardButton: ElementRef;
  @ViewChild('inputField') public inputField: ElementRef;

  public AdItemClick = AdItemClick;
  public PaylogixAmount = PaylogixAmount;
  public subscriptions: Subscription[] = [];
  public isSaved$: Observable<boolean>;
  public previousLogoOptions: ImageUrlPipeOptions;
  public paylogixProductDetails = [];

  public previousWindowWidth = 0;
  public knowUserTypeFromStorage = '';
  public cardInput = '';

  public mouseJustLeft = false;
  public isSelected = false;
  public isSignedInAsKnownUser = true;
  public isAccessLockVisible = false;
  public showConfirm = false;
  public showPassword = false;
  public isEnrollmentAvailable = false;

  private isSavedSource = new BehaviorSubject<boolean>(false);

  public constructor(
    public directClickOutService: DirectClickOutService,
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    private myOffersService: MyOffersService,
    private modalService: NgbModal,
    private shareInfoService: ShareInfoService,
    private profileService: ProfileService,
    private knownUserService: KnownUserService,
    protected clickOutService: ClickOutService,
    protected offersAnalytics: OffersAnalyticsService,
    private readonly paylogixService: PaylogixService,
    private readonly openEnrollmentService: OpenEnrollmentService,
    private readonly accountConfirmationService: AccountConfirmationService,
    @Inject(WINDOW) private window: WINDOW,
  ) {
    // TODO move to just premade isSavedSource instead
    this.isSaved$ = this.isSavedSource?.asObservable();
  }


  public get adCtaButtonOptions(): IAdCTAButtonOptions {
    return {
      ad: this.ad,
      customClass: this.isTier1 ? '' : 'ad-card',
      isEnrollmentAvailable: this.isEnrollmentAvailable,
      marketplaceHasPaylogixId: this.marketplaceHasPaylogixId,
      userHasPaylogixId: this.paylogixService.userHasPaylogixId()
    };
  }

  public get manageCtaOptions(): IAdCTAButtonOptions {
    return {
      ad: this.ad,
      isEnrollmentAvailable: this.isEnrollmentAvailable,
      marketplaceHasPaylogixId: this.marketplaceHasPaylogixId,
      userHasPaylogixId: this.paylogixService.userHasPaylogixId()
    };
  }

  public get isProductCard(): boolean {
    return this.ad && this.ad.class && this.ad.class === 'Product' && !this.forceStandardCardTemplate;
  }

  public get isSavedOffer(): boolean {
    return this.ad ? this.ad.is_saved : false;
  }

  public get isOfferCardHasInputField(): boolean {
    return this.ad.destinationUrl && this.ad.destinationUrl.toUpperCase().includes('%ZIPCODE%') && this.ad.code === '';
  }

  public get isOfferNotEnrolled(): boolean {
    return this.paylogixService.isOfferCardPaylogix(this.ad) && this.marketplaceHasPaylogixId && !this.ad.paylogixSubscription;
  }

  public get isPaylogixDataIncomplete(): boolean {
    return this.paylogixService.isOfferCardPaylogix(this.ad) && !this.paylogixService.userHasPaylogixId();
  }

  public get isPaylogixOffer(): boolean {
    return this.paylogixService.isOfferCardPaylogix(this.ad);
  }

  public get isUserEnrolledPaylogix(): boolean {
    return !!get(this.profileService.getData(), 'vendorSettings.paylogixParticipantId', '');
  }

  public get marketplaceHasPaylogixId(): boolean {
    return this.paylogixService.marketplaceHasPaylogixId();
  }
  public get brandTitle(): string {
    if (this.ad.brandOverride) {
      return this.ad.brandOverride;
    } else if (this.ad.vendorBrand && this.ad.vendorBrand.title) {
      return this.ad.vendorBrand.title;
    } else if (this.ad.company) {
      return this.ad.company;
    } else {
      return '';
    }
  }

  public get deductionPillShown(): boolean {
    return this.ad.catCodeTagIds && this.ad.catCodeTagIds.indexOf(EXTRA_CAT_CODE_TAG_ID_PAYROLL) !== -1;
  }

  get imageUrl (): string {
    if (this.ad?.imageSource === 'default') {
      return this.ad?.vendorImage;
    }

    return this.ad?.dbImageUrl;
  }

  public async ngOnInit(): Promise<void> {
    this.ariaLabel = this.ad.titleShort || this.ad.title;
    this.disableHover = this.isAllowDisableHover ? this.directClickOutService.adCards.disableHover(this.ad) : false;
    this.cardInputEnabled = this.isOfferCardHasInputField;
    this.isSignedInAsKnownUser = !!this.profileService.getOption('isSignedInAsKnownUser');
    // Set initial state to save button
    this.isSavedSource.next(this.isSavedOffer);
    if (this.isPaylogixOffer && this.isUserEnrolledPaylogix) {
      // set paylogix specific information for matched user for paylogix offer
      this.paylogixProductDetails = this.paylogixService.getFormattedPaylogixProductData(this.ad);
    }
    this.subscriptions.push(this.knownUserService._knowUserType$.subscribe((type: KnownUserType) => {
      if (!type) {
        type = this.knownUserService.knowUserTypeFromStorage || '' as KnownUserType;
      }

      const showSignIn =  this.knownUserService.getIsNotSetUnconfirmed() && this.accountConfirmationService.isLoginWithoutPassword;
      this.showConfirm = this.knownUserService.getIsConfirmInfoCheck(type) && !showSignIn;
      this.showPassword = type.includes('not_set')
        && !this.knownUserService.isKnownSkipPassword;
    }));

    this.subscriptions.push(this.openEnrollmentService.getEnrollmentInfo()
      .pipe(distinctUntilChanged())
      .subscribe(info => {
        this.isEnrollmentAvailable = info ? info.isEnrollmentAvailable : false;
      })
    );
  }

  public ngAfterViewInit(): void {
    if (this.ad) {
      this.displayed.emit(this.ad);
    }

    this.handleSubHeaderStyles();
  }

  public openPreviewModal(): void {
    const modalRef = this.modalService.open(AdDisclaimerModalComponent, {
      windowClass: 'ad-disclaimer-modal',
      backdrop: 'static',
      animation: false
    });
    modalRef.componentInstance.ad = this.ad;

    const size = this.isTier1 ? 'double' : 'standard';

    const adobeInfo = this.adobeAnalyticsEventsService.getAdobeClickInfo(this.ad, size, this.zoneName, this.isEnrollment);
    adobeInfo.event_type = AdobeEventTypeEnum.INFORMATION;
    adobeInfo.event_name = this.isSearchResult ? AdobeEventNameEnum.SEARCH_LISTING : AdobeEventNameEnum.CARD;
    adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(this.ad);

    void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
  }

  public shareClick(ad: Ad): void {
    this.shareInfoService.copyInfo(`${this.brandTitle}\n|\n${ad.headerShort}\n|\n${ad.destinationUrl}`);
  }

  public getProductCtaTitle(): string {
    if (!this.ad) {
      return '';
    }
    return this.ad.getCtaButtonTitle(true);
  }

  public onMouseLeave(): void {
    this.mouseJustLeft = true;
  }

  public async markOffer({ isSaved }, ad: Ad): Promise<void> {
    const offer = this.directClickOutService.adCards.getOfferFromAd(ad);
    if (isSaved) {
      this.directClickOutService.adCards.increaseViewCounter(offer);
    }

    const size = AdobeCardSizeEnum.STANDARD;

    const adobeInfo = this.adobeAnalyticsEventsService.getAdobeClickInfo(this.ad, size, this.zoneName, this.isEnrollment);
    adobeInfo.event_type = isSaved ? AdobeEventTypeEnum.FAVORITE : AdobeEventTypeEnum.UNFAVORITE;
    adobeInfo.event_name = this.isSearchResult ? AdobeEventNameEnum.SEARCH_LISTING : AdobeEventNameEnum.CARD;
    adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(this.ad);
    void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);

    await this.myOffersService.markOfferAsFavorite(offer.guid, isSaved, this.isEnrollment);

    this.ad.is_saved = isSaved;
    this.isSavedSource.next(isSaved);
  }

  public isShowCountViews(): boolean {
    const minCountViewsToShow = 25;
    return (this.ad.views >= minCountViewsToShow) && !this.paylogixService.isOfferCardPaylogix(this.ad);
  }

  public handleImageLoad(event: string): void {
    this.viewInit.emit(event);
  }

  public async completePaylogixInfo(): Promise<void> {
    if (this.knownUserService.isKnownUser()) {
      this.accountConfirmationService.checkAndOpenDialog();
      return;
    }

    await this.paylogixService.activateCompleteInfoModal();
    this.accountConfirmationService.openPaylogixProfileModal('Homepage');
  }

  public async handleCtaClick(event: Event, clickType?: AdItemClick): Promise<void> {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.ad.is_widget_iframe) {
      this.clicked.emit(this.ad);
      return;
    }

    if (this.ad.isPaylogixAd) {
      if (this.ad.descriptionDetails && clickType !== AdItemClick.CTA) {
        // Navigate to details page
        this.clicked.emit(this.ad);
        return;
      }

      if (this.isPaylogixDataIncomplete) {
        return this.completePaylogixInfo();
      } else {
        return this.onPaylogixAdClicked(event);
      }
    }

    switch (clickType) {
      case AdItemClick.CTA:
        const ctaAdSource = this.isOfferNotEnrolled ? 'paylogix-enrollment' : 'cta';
        this.clickedCta.emit({ ad: this.ad, source: ctaAdSource, params: {} });
        break;
      case AdItemClick.CTACard:
        const params = this.cardInput ? { zipcode: this.cardInput } : {};
        this.clickedCta.emit({ ad: this.ad, source: 'cta-zipcode', params });
        break;
      default:
        this.clicked.emit(this.ad);
    }
  }

  public changeFocus(inputLength: number): void {
    const zipCodeMaxLength = 5;
    const isExpectedZipLength = inputLength === zipCodeMaxLength;
    this.isSelected = isExpectedZipLength;
    if (isExpectedZipLength) {
      this.inputCardButton.nativeElement.focus();
    }
  }

  public onInputClick(event: Event): void {
    event.stopPropagation();
    this.inputField.nativeElement.focus();
  }

  public onAccessLockVisibilityChange(value: boolean): void {
    this.isAccessLockVisible = value;
  }

  public getLogoOptions(): ImageUrlPipeOptions {
    const width = this.window.innerWidth;

    // This is no cache. Reason of it do not get smaller images if we already got bigger one. (get bigger only if currently we have smaller)
    if (width <= this.previousWindowWidth) {
      return this.previousLogoOptions;
    }

    this.previousLogoOptions = width <= WindowHelper.SIZE_S
      ? this.isTier1 ? logoOptionsMedium : logoOptionsSmall
      : this.isTier1 ? logoOptionsLarge : logoOptionsMedium;

    this.previousWindowWidth = width;

    return this.previousLogoOptions;
  }

  public getImageOptions(): ImageUrlPipeOptions {
    const width = this.window.innerWidth;

    if (this.isOfferCardHasInputField) {
      if (this.parentType === 'cards_fixed') {
        if (width <= WindowHelper.SIZE_S) {
          return this.isTier1 ? imageOptionsWithInput.cardsFixed.doubled.XS : imageOptionsWithInput.cardsFixed.simple.XS;
        } else if (width <= WindowHelper.SIZE_M) {
          return this.isTier1 ? imageOptionsWithInput.cardsFixed.doubled.S : imageOptionsWithInput.cardsFixed.simple.S;
        } else if (width <= WindowHelper.SIZE_L) {
          return this.isTier1 ? imageOptionsWithInput.cardsFixed.doubled.M : imageOptionsWithInput.cardsFixed.simple.M;
        } else {
          return this.isTier1 ? imageOptionsWithInput.cardsFixed.doubled.OTHER : imageOptionsWithInput.cardsFixed.simple.OTHER;
        }
      } else {
        if (width <= WindowHelper.SIZE_S) {
          return this.isTier1 ? imageOptionsWithInput.cardsScroll.doubled.XS : imageOptionsWithInput.cardsScroll.simple.XS;
        } else if (width <= WindowHelper.SIZE_M) {
          return this.isTier1 ? imageOptionsWithInput.cardsScroll.doubled.S : imageOptionsWithInput.cardsScroll.simple.S;
        } else if (width <= WindowHelper.SIZE_L) {
          return this.isTier1 ? imageOptionsWithInput.cardsScroll.doubled.M : imageOptionsWithInput.cardsScroll.simple.M;
        } else {
          return this.isTier1 ? imageOptionsWithInput.cardsScroll.doubled.OTHER : imageOptionsWithInput.cardsScroll.simple.OTHER;
        }
      }
    }

    if (this.parentType === 'cards_fixed') {
      if (width <= WindowHelper.SIZE_S) {
        return this.isTier1 ? imageOptions.cardsFixed.doubled.XS : imageOptions.cardsFixed.simple.XS;
      } else if (width <= WindowHelper.SIZE_M) {
        return this.isTier1 ? imageOptions.cardsFixed.doubled.S : imageOptions.cardsFixed.simple.S;
      } else if (width <= WindowHelper.SIZE_L) {
        return this.isTier1 ? imageOptions.cardsFixed.doubled.M : imageOptions.cardsFixed.simple.M;
      } else {
        return this.isTier1 ? imageOptions.cardsFixed.doubled.OTHER : imageOptions.cardsFixed.simple.OTHER;
      }
    } else {
      if (width <= WindowHelper.SIZE_S) {
        return this.isTier1 ? imageOptions.cardsScroll.doubled.XS : imageOptions.cardsScroll.simple.XS;
      } else if (width <= WindowHelper.SIZE_M) {
        return this.isTier1 ? imageOptions.cardsScroll.doubled.S : imageOptions.cardsScroll.simple.S;
      } else if (width <= WindowHelper.SIZE_L) {
        return this.isTier1 ? imageOptions.cardsScroll.doubled.M : imageOptions.cardsScroll.simple.M;
      } else {
        return this.isTier1 ? imageOptions.cardsScroll.doubled.OTHER : imageOptions.cardsScroll.simple.OTHER;
      }
    }
  }

  public onPaylogixAdClicked(event: Event): void {
    event.stopPropagation();
    const analyticEvents = this.offersAnalytics.getAnalyticsEvents('click-out', this.ad);
    const adobeInfo = this.adobeAnalyticsEventsService.getAdobeClickInfo(this.ad, this.isTier1 ? 'double' : 'standard', this.zoneName, this.isEnrollment);
    adobeInfo.event_type = AdobeEventTypeEnum.EXTERNAL_CLICKOUT;
    adobeInfo.properties.offer.isEbg = this.adobeAnalyticsEventsService.getIsEbg(this.ad);
    void this.adobeAnalyticsEventsService.sendOfferClick(adobeInfo);
    void this.clickOutService.clickOut(this.ad.destinationUrl, '', {
      guid: this.ad.contentGuid,
      analyticEvents,
      data: {
        logo: this.ad.vendorImage,
        brand: this.ad.company,
        guid: this.ad.contentGuid,
        urlParams: this.ad.append_url_params,
        externalData: this.ad.external_data
      }
    });
  }

  public ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  private handleSubHeaderStyles(): void {
    const productSubHeaderInnerHTML = document.querySelectorAll('.ad-sub-heading-info > b');
    if (productSubHeaderInnerHTML && productSubHeaderInnerHTML.length) {
      productSubHeaderInnerHTML.forEach((node: HTMLElement) => {
        node.style.color = '#353535';
        node.style.display = 'inline-block';
        node.style.fontSize = '16px';
        if (window.innerWidth < WindowHelper.SIZE_S) {
          node.style.fontSize = '14px';
          node.style.display = 'block';
        }
      });
    }
  }
}
