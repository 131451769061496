/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@shared/models/analytics.event.model';
import { AnalyticsService } from '@shared/services/analytics.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CustomWidgetComponent } from '../custom-widget/custom-widget.component';
import { CustomWidgetDirective } from '../custom-widget/custom-widget.directive';
import { CustomWidgetModel } from '@app/shared/models/custom-widget.model';
import { environment } from '@environments/environment';
import { HotelsMiniComponent } from '../mini/hotels-mini/hotels-mini.component';
import { HotelWidgetComponent } from '@app/widgets/custom/hotel/hotel-widget.component';
import { HttpClient } from '@angular/common/http';
import {
  INSURANCE_MINI,
  RENTAL_CAR_MINI,
  TAW_HOTELS_LARGE,
  TAW_HOTELS_MINI,
  TICKETS_MINI,
  TRUECAR_LARGE,
  TRUECAR_MINI,
  isValidWidgetId,
  CustomHotelWidgetIds,
  ValidCustomWidgetIdTypes,
  CustomRentalCarWidgetIds,
  TAW_HOTELS_SEARCH,
} from '../constants/widget-ids.constant';
import { InsuranceMiniComponent } from '../mini/insurance-mini/insurance-mini.component';
import { RentalCarMiniComponent } from '../mini/rental-car-mini/rental-car-mini.component';
import { TicketsMiniComponent } from '../mini/tickets-mini/tickets-mini.component';
import { TrueCarComponent } from '@app/widgets/custom/truecar/truecar.component';
import { TruecarMiniComponent } from '@widgets/mini/truecar-mini/truecar-mini.component';
import { UrlHelperService } from 'g3-common-ui';
import { WidgetModel } from '@app/shared/models/widget.model';
import uniq from 'lodash-es/uniq';
import { AdobeAnalyticsEventsService } from '@app/shared/services/adobe/units/adobe-analytics-events.service';
import { Ad } from '@app/shared/models/ad.model';
import { AdobeEventNameEnum } from '@app/shared/services/adobe/adobe-analytics.interfaces';

export const WidgetsComponents = [
  { id: TRUECAR_LARGE, component: TrueCarComponent },
  { id: TAW_HOTELS_LARGE, component: HotelWidgetComponent },
  { id: TAW_HOTELS_MINI, component: HotelsMiniComponent },
  { id: TRUECAR_MINI, component: TruecarMiniComponent },
  { id: INSURANCE_MINI, component: InsuranceMiniComponent },
  { id: RENTAL_CAR_MINI, component: RentalCarMiniComponent },
  { id: TICKETS_MINI, component: TicketsMiniComponent },
];


@Injectable()
export class WidgetsService {

  widgetsSource = new BehaviorSubject<WidgetModel[]>([]);
  widgets$ = this.widgetsSource.asObservable();

  constructor(
    private analyticsService: AnalyticsService,
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService,
    private urlHelper: UrlHelperService,
    private http: HttpClient
  ) { }

  isWidgetModelValid(widget: WidgetModel): boolean {
    if (widget === null) {
      return false;
    }

    if (widget instanceof CustomWidgetModel) {
      // If we have no component to render, this is not a valid widget model
      if (!this.hasWidgetComponent(widget.widgetId)) {
        return false;
      }
    }

    return true;
  }

  hasWidgetComponent(widgetId: any): boolean {
    return WidgetsComponents.some(x => x.id === widgetId);
  }

  findWidgetComponent(widgetId: any): any {
    const widget = WidgetsComponents.find(x => x.id === widgetId);
    if (!widget) {
      return null;
    }
    return widget.component;
  }

  setNextWidgets(widgets: WidgetModel[]): void {
    const notEmptyWidgets = widgets.filter(widget => WidgetsComponents.map(item => item.id).includes(widget.widgetId));
    this.widgetsSource.next(notEmptyWidgets);
  }

  render(widget: CustomWidgetModel, widgetHost: CustomWidgetDirective, groupTabIndex: number): any {
    if (!widgetHost) {
      return;
    }

    const widgetComponentType = this.findWidgetComponent(widget.widgetId);
    if (!widgetComponentType) {
      return;
    }

    setTimeout(() => {
      widgetHost.viewContainerRef.clear();
      const componentRef = widgetHost.viewContainerRef.createComponent(widgetComponentType);
      const component = componentRef.instance as CustomWidgetComponent;
      component.model = widget;
      component.customClass = widget && widget.widgetId && widget.widgetId.includes('mini') ? 'mini-widget' : 'large-widget';
      component.groupTabIndex = groupTabIndex;
      component.clickOut.subscribe(({ model, url }) => {
        this.widgetClickOut(model, url);
      });
    });
  }

  /**
   * Filter Widget Badges based on CAT Codes
   * Currently used by specific hotel search widgets in "multi-search" widget.
   * Can be extended to add more filter logic later and added to the specific component.
   */
  filterBadgesCatCodes(widgetId: string | ValidCustomWidgetIdTypes, catCodeTagIds: number[]): number[] {
    const id = widgetId.toLowerCase().trim();
    if (!isValidWidgetId<ValidCustomWidgetIdTypes>(id)) {
      return catCodeTagIds;
    }
    // * Filter out the loyalty badges 1x-3x for widgets for hotels, keep 4x
    const [loyaltyFrom, loyalTo] = [300_000, 300_004];
    if (isValidWidgetId<typeof CustomHotelWidgetIds[number]>(id) && id === TAW_HOTELS_SEARCH) {
      let codes = catCodeTagIds.filter((catCodeTagId: number) => catCodeTagId < loyaltyFrom || catCodeTagId >= loyalTo);
      codes = uniq([...codes, loyalTo]); // * force the 4x badge for search widget
      return codes;
    }

    // * Filter out the loyalty badges 1x-4x for widgets for rental cars
    if (isValidWidgetId<typeof CustomRentalCarWidgetIds[number]>(id) && CustomRentalCarWidgetIds.indexOf(id) > -1) {
      return catCodeTagIds.filter((catCodeTagId: number) => catCodeTagId < loyaltyFrom || catCodeTagId > loyalTo);
    }

    return catCodeTagIds;
  }

  widgetDisplayed(widget: CustomWidgetModel): void {
    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('widget-mini-displayed', {
        'widget-type': widget.widgetId,
        ad_id: widget.id
      }),
      new AnalyticsGAEventModel('widget-mini-displayed', {
        category: 'widget-mini',
        label: `${widget.widgetId}|${widget.guid}`
      }),
    ]);

    this.http.post(`${environment.apiUrl}/decisions/impressions`,
      { impressions: [{ response_id: widget.responseId }] }).toPromise().catch(() => { });
  }

  widgetClickOut(widget: CustomWidgetModel, url: string): void {
    const clickOut = widget.isEbg || !this.urlHelper.isExternalUrl(url) ? 'internal' : 'external';

    this.analyticsService.eventsTrack([
      new AnalyticsInternalEventModel('widget-mini-click', {
        'widget-type': widget.widgetId,
        ad_id: widget.id,
        url,
        click_out: clickOut
      }),
      new AnalyticsGAEventModel('widget-mini-click', {
        category: 'widget-mini',
        label: `${widget.widgetId}|${widget.guid}|${clickOut}`
      }),
    ]);

    this.http.post(`${environment.apiUrl}/decisions/clicks`,
      { response_id: widget.responseId }).toPromise().catch(() => { });

    void this.sendAdobeAnalytics(widget);
  }

  async sendAdobeAnalytics(widget: CustomWidgetModel): Promise<void> {
    const ad = new Ad({
      guid: widget.guid,
      isEbg: widget.isEbg,
      content: [{ key: 'header', value: widget.header }],
      destinationUrl: widget.destinationUrl,
      brand_name: widget.brandName,
      catCodeTagIds: widget.catCodeTagIds,
    });
    this.adobeAnalyticsEventsService.sendWidgetClick({
      event_name: AdobeEventNameEnum.WIDGET,
      event_type: this.adobeAnalyticsEventsService.getClickOutValueForWidget(ad, widget.widgetId),
      properties: await this.adobeAnalyticsEventsService.getWidgetAdobeData(ad)
    });
  }

  formatDate(date: Date): string {
    const trimmedDate = date.toISOString().substring(0, 10);
    const browser = this.getBrowser();

    if (browser === 'IE') {
      const dateParts = trimmedDate.split('-');

      return [dateParts[1], dateParts[2], dateParts[0]].join('/');
    } else {
      return trimmedDate;
    }
  }

  getBrowser(): string {
    const ua = navigator.userAgent;

    if (ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1) {
      return 'IE';
    }

    return 'OTHER';
  }

  addDaysToDate(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  }

}
