import cloneDeep from 'lodash-es/cloneDeep';
import { ClickOutService } from '@app/shared/services/click-out.service';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { EbgProductSearchResponse } from '@app/widgets/interfaces/ebg-product-search.interface';
import { OPACITY_FADE_IN_OUT_ANIMATION, REVERSE_HEIGHT_ANIMATION } from '@app/shared/animations/slide-in-out-panel.animation';
import { SearchWidgetsData } from '@app/offers/interfaces/search-widgets.interface';
import { ShareInfoService } from '@app/shared/services/share-info.service';
import { TicketsQueryParams } from '../tickets-search/tickets-query-params.interface';
import { TicketsService } from '@app/widgets/services/tickets.service';
import { UrlService } from '@app/shared/services/url.service';
import { OFFERS_BP, STORE_WA } from '@app/shared/constants/url.constants';

const ANIMATION_TIMEOUT = 1000;
const INITIAL_SHOW_MORE_COUNT = 0;

@Component({
  selector: 'app-tickets-search-result',
  templateUrl: './tickets-search-result.component.html',
  styleUrls: ['./tickets-search-result.component.less', '../common-widget.style.less'],
  animations: [OPACITY_FADE_IN_OUT_ANIMATION, REVERSE_HEIGHT_ANIMATION]
})
export class TicketsSearchResultComponent implements OnChanges {

  @Input() customClass: string;
  @Input() showMoreCountStringValue: string;
  @Input() searchResult: EbgProductSearchResponse[] = [];
  @Input() widget: SearchWidgetsData;
  @Input() ticketsInputData: Partial<TicketsQueryParams>;

  @ViewChild('ticketsResultContainer') ticketsResultContainer: ElementRef;

  isLoading = false;
  isShowMore: boolean;
  showMoreCount: number;
  ticketsData: EbgProductSearchResponse[] = [];

  constructor(
    private shareInfoService: ShareInfoService,
    private clickOutService: ClickOutService,
    private ticketsService: TicketsService,
    private urlService: UrlService
  ) { }

  get isNoResult(): boolean {
    return !this.isLoading && this.searchResult.length < 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initTicketsSearchResultData();
    this.ticketsData = this.getSlicedTicketsData();
    this.isShowMore = this.getIntShowMoreCount() < this.searchResult.length;

    if (changes.searchResult && changes.searchResult.previousValue) {
      this.ticketsService.ticketsResultAnalyticsOnDisplay(this.widget, this.ticketsInputData.tw_q, this.ticketsInputData.tw_city_zip);
    }
  }

  onShowMore(): void {
    this.ticketsData = this.searchResult;
    this.isShowMore = false;
  }

  getSlicedTicketsData(): EbgProductSearchResponse[] {
    this.showMoreCount = this.getIntShowMoreCount();
    return this.showMoreCount && this.showMoreCount > INITIAL_SHOW_MORE_COUNT && this.showMoreCount < this.searchResult.length
      ? cloneDeep(this.searchResult.slice(INITIAL_SHOW_MORE_COUNT, this.showMoreCount))
      : this.searchResult;
  }

  shareClick(item: EbgProductSearchResponse): void {
    const destinationUrl = this.getDestinationUrlByHostName(item.url);
    const offerInfo = `${item.short_name}\n${item.short_description}\n${destinationUrl}`;
    this.shareInfoService.copyInfo(offerInfo);
  }

  async onGetOffer(item: EbgProductSearchResponse): Promise<void> {
    const destinationUrl = this.getDestinationUrlByHostName(item.url);
    this.ticketsService.ticketsResultAnalyticsOnClick(this.widget, destinationUrl);
    await this.clickOutService.clickOut(destinationUrl, null, {}, false);
  }

  private getIntShowMoreCount(): number {
    const showMoreCountInt = parseInt(this.showMoreCountStringValue, 10);
    return showMoreCountInt ? showMoreCountInt : INITIAL_SHOW_MORE_COUNT;
  }

  private initTicketsSearchResultData(): void {
    this.isLoading = true;
    setTimeout(() => this.isLoading = false, ANIMATION_TIMEOUT);
  }

  private getDestinationUrlByHostName(destinationUrl: string): string {
    const isWorkingAdvantage = this.urlService.isWorkingAdvantage(window.location.hostname);
    if (!isWorkingAdvantage && destinationUrl.includes(STORE_WA)) {
      return destinationUrl.replace(STORE_WA, OFFERS_BP);
    } else if (isWorkingAdvantage && destinationUrl.includes(OFFERS_BP)) {
      return destinationUrl.replace(OFFERS_BP, STORE_WA);
    } else {
      return destinationUrl;
    }
  }

}
